import React from 'react';
import styled from 'styled-components';

interface KanbanCardActionProps {
  icon: React.ReactElement;
  onClick: (e: React.MouseEvent) => void;
  text?: string;
}

export const KanbanCardAction: React.FC<KanbanCardActionProps> = ({ icon, onClick, text }) => (
  <Container onClick={onClick}>
    {icon}
    {text && <span className="caption">{text}</span>}
  </Container>
);

const Container = styled.div`
  height: 2.4rem;
  min-width: 2.4rem;
  padding: 0 0.4rem;
  background-color: var(--app-background);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 0.4rem;
  gap: 0.4rem;
  color: var(--color-grayscale-manatee);
  user-select: none;

  &:hover {
    color: var(--color-texts-high-contrast);
    cursor: pointer;
  }
`;
