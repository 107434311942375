import React, { ChangeEvent, useRef, useState } from 'react';
import styled from 'styled-components';

import { ElevatedButton, IconButton } from '../../../components/buttons';
import { FloatingActionButton } from '../../../components/buttons/floating-action-button';
import { breakPoint } from '../../../app/theme';

type IconUploadButtonProps = {
  text?: never;
  icon: React.ReactNode;
  variant: 'icon';
};

type TextUploadButtonProps = {
  text: string;
  icon?: React.ReactNode;
  variant?: 'default';
};

type UploadButtonProps = (IconUploadButtonProps | TextUploadButtonProps) & {
  accept?: string;
  multiple?: boolean;
  shouldAddFloatButton?: boolean;
  onUploadFileValue: (files: File[]) => Promise<void>;
};

export const UploadButton: React.FC<UploadButtonProps> = ({
  text,
  onUploadFileValue,
  icon,
  accept = '*',
  shouldAddFloatButton = true,
  multiple,
  variant = 'default',
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const inputFilesRef = useRef<HTMLInputElement>(null);

  const handleAddFilesChange = async (event: ChangeEvent<HTMLInputElement>) => {
    if (isLoading || !inputFilesRef?.current) {
      return;
    }

    const files = event.currentTarget.files;

    if (!files) {
      return;
    }

    setIsLoading(true);

    const filesArray = Array.from(files);
    await onUploadFileValue(filesArray);

    setIsLoading(false);
  };

  const handleClickAddFiles = () => {
    if (isLoading || !inputFilesRef?.current) {
      return;
    }

    inputFilesRef.current.click();
  };

  return (
    <React.Fragment>
      <ButtonWrapper data-shouldAddFloatButton={shouldAddFloatButton}>
        {variant === 'icon' ? (
          <IconButton icon={icon} onClick={handleClickAddFiles} />
        ) : (
          <ElevatedButton
            text={text!}
            onClick={handleClickAddFiles}
            icon={icon}
            isLoading={isLoading}
          />
        )}
      </ButtonWrapper>

      <InputFile
        ref={inputFilesRef}
        id="file"
        type="file"
        accept={accept}
        name="file"
        onChange={handleAddFilesChange}
        multiple={multiple}
      />

      {shouldAddFloatButton && <FloatingActionButton onClick={handleClickAddFiles} />}
    </React.Fragment>
  );
};

const ButtonWrapper = styled.div`
  &[data-shouldAddFloatButton='true'] {
    @media screen and (max-width: ${breakPoint.medium - 1}px) {
      display: none;
    }
  }
`;

const InputFile = styled.input`
  display: none;
`;
