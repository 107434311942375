import React, { useEffect, useState } from 'react';
import { PageLoader } from '@producer-io/ui-kit';
import { observer } from 'mobx-react-lite';

import { DepartmentList } from '../components/department-list';
import { CreateNewDepartmentModal } from '../components/create-department.modal';
import { LoaderContainer } from '../../../routes/project/components/step-container';
import { ErrorScreen } from '../../../components/error-screen/ErrorScreen';
import { createTeam, fetchTeam } from '../stores/team.slice';
import { TeamHeader } from '../components/team-screen.header';
import { StepDrawer } from '../../projects/components/step.drawer';
import { catchError } from '../../../core/catch-error';
import { Team } from '../../../app/entities/team';

import type { Project } from '../../../app/entities/project';
import type { ScreenProps } from '../../projects/models/types';
import styled from 'styled-components';
import { uiStore } from '../../../core/stores/ui-store';
import { TaskPanel } from '../../tasks/components/task.panel';

type TeamScreenProps = ScreenProps & { spaceId: Project['spaceId'] };

export const TeamScreen: React.FC<TeamScreenProps> = observer(({ step, projectId, spaceId }) => {
  const [isLoading, setIsLoading] = useState(!step);
  const [isAddDepartmentModalOpen, setIsAddDepartmentModalOpen] = useState(false);

  const team = Team.getOne(step.info?.teamId || '');

  useEffect(() => {
    if (!step.info?.teamId) {
      setIsLoading(false);
      return;
    }

    fetchTeam(step.info.teamId).then(() => {
      setIsLoading(false);
    });
  }, [step.info?.teamId]);

  const handleOpenAddDepartment = () => {
    setIsAddDepartmentModalOpen(true);
  };

  const handleCreateCrewTeam = async () => {
    setIsLoading(true);

    try {
      const team = await createTeam({ projectId, name: 'Crew', stepId: step._id });

      if (!step.info) {
        step.info = {
          teamId: team._id,
        };
      } else {
        step.info.teamId = team._id;
      }

      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      catchError(e);
    }
  };

  const renderContent = () => {
    if (isLoading) {
      return (
        <LoaderContainer>
          <PageLoader />
        </LoaderContainer>
      );
    } else if (!team) {
      return (
        <ErrorScreen
          title="You do not have a Crew team for this project yet"
          message="Start by creating your Crew team."
          actionText="Create Crew Team"
          onActionClick={handleCreateCrewTeam}
        />
      );
    }

    return (
      <Container>
        <DepartmentList
          spaceId={spaceId}
          projectId={projectId}
          team={team}
          onOpenAddDepartment={handleOpenAddDepartment}
        />

        {uiStore.isTaskPanelOpen && !!uiStore.taskPanelTargetId && (
          <TaskPanel targetId={uiStore.taskPanelTargetId} />
        )}
      </Container>
    );
  };

  return (
    <React.Fragment>
      <TeamHeader step={step} onOpenAddDepartment={handleOpenAddDepartment} />

      <StepDrawer step={step} />

      {renderContent()}

      {isAddDepartmentModalOpen && team && (
        <CreateNewDepartmentModal
          team={team}
          onCancel={() => setIsAddDepartmentModalOpen(false)}
          onClose={() => setIsAddDepartmentModalOpen(false)}
        />
      )}
    </React.Fragment>
  );
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 100%;
  overflow-y: auto;
  height: 100%;
  max-height: calc(100% - 4rem);
`;
