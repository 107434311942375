import { action, makeObservable, observable } from 'mobx';

import { SpaceFlags } from '../../features/spaces/models/space.flags';
import { Model } from '../../core/engine/model';
import { Project } from './project';
import { Contact } from './contact';
import { Member } from './member';
import { User } from './user';
import { SORTING_TYPES } from '../../assets/enums/assetType.enum';
import { Invitation } from './invitation';
import { api } from 'api';
import { Toast } from '../../features/toasts/models/toast';
import { toastPool } from '../../features/toasts/models/toast-pool';
import { OneToMany } from '../../core/engine/decorators';
import { catchError } from '../../core/catch-error';
import { Task } from './task';

export interface Seats {
  current: number;
  limit: number;
}

export interface Storage extends Seats {
  current: number;

  limit: number;

  region: 'eu-west' | 'us-west' | 'brazil-south';

  provider: 'azure' | 'aws' | 'custom';
}

export enum SubscriptionStatus {
  ACTIVE = 'active',
  PAST_DUE = 'past_due',
  TRIAL = 'trialing',
}

class Transaction {
  transactionId: string;
  date: Date;
  description: string;
  total: number;
  status: string;
  currency: string;
  hasInvoice: boolean;

  /**
   * Return the total paid formatted as a string
   */
  get amount() {
    return new Intl.NumberFormat(undefined, {
      style: 'currency',
      currency: this.currency,
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    }).format(this.total / 100);
  }
}

interface SubscriptionSchema {
  /**
   * Name of the provider: paddle, marketplace
   */
  provider: 'paddle' | 'marketplace';

  /**
   * ID of the customer in the provider
   *
   * In Paddle the customer is linked to a user email and should always be used in combination
   * with the businessId to target the correct space when the subscription id is not yet available
   */
  customerId: string;

  /**
   * Space information should be linked to a business on Paddle as a user can manage subscriptions
   * for multiple Spaces
   */
  businessId: string;

  /**
   *
   */
  addressId?: string;

  /**
   * The id of the current subscription,
   *
   * The subscriptionId can be undefined as we depend on Webhook to receive the newly created
   * subscription and there can be a delay between the creation of the subscription and the webhook
   * being received and handled by Producer
   */
  subscriptionId?: string | null;

  /**
   * On Paddle, the subscription is based on a price id for user
   */
  userPriceId?: string;

  /**
   * The current subscription status. Updated by Gateway Webhooks
   */
  status?: SubscriptionStatus;

  /**
   * How payment is collected for transactions created for this subscription. automatic for checkout, manual for invoices.
   * If it is undefined, then the subscription is self-service
   */
  collectionMode?: 'automatic' | 'manual';

  /**
   * Date when the status was set to past_due
   */
  past_due_at?: string | null;
}

export class Space extends Model {
  /** The name of the Space **/
  name: string;

  /**
   * Alternative, url-safe, name for organisations
   */
  alias?: string;

  avatar: string;
  logo: string;
  ownerId: string;
  storage: Storage = {
    limit: Math.pow(2, 30) * 4.66, // 5GB
    current: Math.pow(2, 30) * 1.86, // 2GB
    provider: 'azure',
    region: 'eu-west',
  };
  flags?: SpaceFlags;

  // seats of current non self-served plan
  seats?: {
    /**
     * Current seats use
     */
    current: number;

    /**
     * Maximum seats
     */
    limit: number;
  };

  billingDetails?: {
    taxIdentifier?: string;
    nextRenewal?: Date;
    address?: {
      address: string;
      city: string;
      postalCode: string;
      countryCode: string;
      region?: string;
    };
  };

  transactions?: Transaction[] = [];

  /** The Space's projects **/
  @OneToMany('space')
  projects: Project[] = [];

  /** The Space's contacts **/
  @OneToMany('space')
  contacts: Contact[] = [];

  /** The Space's members **/
  @OneToMany('space')
  members: Member[] = [];

  /** The Space's invitations **/
  @OneToMany('space')
  invitations: Invitation[] = [];

  @OneToMany('space')
  tasks: Task[] = [];

  sortCandidatesBy: string = SORTING_TYPES.A_TO_Z;
  subscription: SubscriptionSchema | null;

  get owner() {
    return Model.getOne(this.ownerId) as User;
  }

  get fps(): number {
    if (['us-west', 'brazil-south'].includes(this.storage.region)) {
      return 29.97;
    } else {
      return 25;
    }
  }

  get isSubscriptionPastDue() {
    return (
      this.subscription?.subscriptionId && this.subscription?.status === SubscriptionStatus.PAST_DUE
    );
  }

  constructor() {
    super('spaces');
    this.tasks = [];

    makeObservable<Space>(this, {
      name: true,
      avatar: true,
      logo: true,
      ownerId: true,
      storage: true,
      flags: true,
      seats: true,
      billingDetails: true,
      transactions: true,
      contacts: true,
      createdAt: true,
      updatedAt: true,
      sortCandidatesBy: true,
      members: observable,
      invitations: observable,
      subscription: observable,
      projects: observable,
      tasks: observable,
      updateSubscription: action,
    });
  }

  toPOJO(): Record<string, any> {
    const pojo = {
      _id: this._id,
      name: this.name,
      avatar: this.avatar,
      logo: this.logo,
      ownerId: this.ownerId,
      storage: this.storage,
      flags: this.flags,
      seats: this.seats,
      billingDetails: this.billingDetails,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
    };

    // if a value is undefined, we remove it from the POJO
    // @ts-ignore
    Object.keys(pojo).forEach((key) => pojo[key] === undefined && delete pojo[key]);
    return pojo;
  }

  hasSubscription(): boolean {
    return !!(this.subscription && this.subscription.subscriptionId);
  }

  hasActiveSubscription(): boolean {
    return !!this.subscription?.subscriptionId && this.subscription.status === 'active';
  }

  updateSubscription(subscription: Omit<SubscriptionSchema, 'provider'>) {
    const updatedSubscription: SubscriptionSchema = { provider: 'paddle', ...subscription };

    this.subscription = this.subscription
      ? Object.assign(this.subscription, updatedSubscription)
      : updatedSubscription;
  }

  async updateIdentificationNumber(payload: { taxIdentifier: string }) {
    try {
      await api.patch(`/spaces/${this._id}/tax`, payload);

      toastPool.insert(new Toast('Identification number is updated successfully.', 'success'));
      return true;
    } catch (e) {
      catchError(e);
      return false;
    }
  }

  async updateBillingAddress(payload: {
    address: string;
    city: string;
    postalCode: string;
    countryCode: string;
    region?: string;
  }) {
    try {
      await api.patch(`spaces/${this._id}/billing-address`, payload);

      toastPool.insert(new Toast('Billing address is updated successfully.', 'success'));
      return true;
    } catch (e) {
      catchError(e);
      return false;
    }
  }

  async getBillingDetails() {
    try {
      const { data } = await api.get(`spaces/${this._id}/billing-details`);

      this.billingDetails = data;
    } catch (e) {
      console.log(e);
    }
  }

  async getBillingHistory() {
    try {
      const { data } = await api.get(`spaces/${this._id}/transactions`);

      this.transactions = data.map((transaction: any) =>
        Object.assign(new Transaction(), transaction),
      );
    } catch (e) {
      console.log(e);
    }
  }

  static getOne(id: string): Space | undefined {
    return Model.getOne(id) as Space;
  }

  static getAll(): Space[] {
    return Model.getAll().filter((model) => model instanceof Space) as Space[];
  }

  get isSelfServing() {
    if (this.subscription?.provider === 'marketplace') return false;

    if (!this.subscription?.subscriptionId) return true;

    return this.subscription.collectionMode !== 'manual';
  }

  canAddNewMember(membersToAdd = 1) {
    // TODO: Remove this line at the 1st of April 2024
    if (this.isSelfServing) return true;

    return !this.isSelfServing ? this.seats!.current + membersToAdd <= this.seats!.limit : true;
  }

  canCreateProject() {
    if (this.hasSubscription()) return true;

    return this.projects.length < 2;
  }

  canExportPdf() {
    return this.hasSubscription();
  }

  canInviteMember() {
    return this.hasSubscription();
  }
}
