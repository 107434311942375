import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import { Project } from '../../../app/entities/project';
import { tabPool } from 'features/tabs/models/tabs-pool';
import { useNavigate } from 'react-router-dom';
import { timeDifference } from '../../../lib/utils/TimeDifference';
import { formatDate } from '../../../lib/utils/DateHelper';
import prettyBytes from 'pretty-bytes';
import { ListViewItem } from '../../../components/list-view/list-view';
import { ProjectCollaborators } from './project-collaborators';
import { useResponsive } from '../../../hooks/useResponsive';
import { CoverImage } from '../../../components/project-item/CoverImage';
import { ProjectMenu } from '../../../features/assets/components/project-menu';
import { PrioritySelector } from '../../process/components/priority-selector';
import { useCurrentMember } from '../../../hooks/use-current-member';

interface ProjectListItemProps {
  project: Project;
}

export const ProjectListItem: React.FC<ProjectListItemProps> = observer(({ project }) => {
  const navigate = useNavigate();
  const { isDesktop } = useResponsive();

  const currentMember = useCurrentMember();

  const handleOpenProject = (e: React.MouseEvent) => {
    const href = `/space/${project.spaceId}/projects/${project._id}`;

    tabPool.newTab({
      key: project._id,
      label: project.name,
      href,
    });

    if (!e.shiftKey) {
      navigate(href);
    }
  };

  return (
    <ListViewItem onClick={handleOpenProject}>
      <ProjectAvatarWrapper>
        <CoverImage src={project.avatar || ''} title={project.name} />
      </ProjectAvatarWrapper>
      <td style={{ width: '100%' }}>{project.name}</td>
      <td style={{ textTransform: 'uppercase' }}>{project.progress}%</td>
      <td>
        {project.usedStorage !== null && project.usedStorage !== undefined
          ? prettyBytes(project.usedStorage)
          : ''}
      </td>
      <td>{project.lastUpdatedAt ? timeDifference(new Date(), project.lastUpdatedAt) : ''}</td>
      <td>{project.dueDate ? formatDate(project.dueDate.toString(), 'MMM dd, yyy') : ''}</td>
      <td>
        <PrioritySelector
          priority={project.priority}
          onChange={(priority) => {
            project.update({ priority });
          }}
          withTitle
        />
      </td>
      {isDesktop && ['owner', 'admin', 'creator'].includes(currentMember?.role || '') && (
        <td onClick={(e) => e.stopPropagation()}>
          <ProjectCollaborators project={project} spaceId={project.spaceId} />
        </td>
      )}
      <td />
      <td style={{ paddingRight: '0.8rem', width: '4.8rem' }}>
        <LastCellWrapper>
          <ProjectMenu project={project} />
        </LastCellWrapper>
      </td>
    </ListViewItem>
  );
});

const ProjectAvatarWrapper = styled.td`
  padding-right: 1.6rem !important;

  img {
    position: relative;
    width: 5.6rem;
    height: 3.2rem;
    border-radius: 0.2rem;
  }
`;

const LastCellWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4.8rem;
`;
