import styled from 'styled-components';
import './index.css';

export const StyledButton = styled.button`
  all: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  height: 3.2rem;
  background-color: var(--button-bgcolor-default);
  border: none;
  border-radius: 0.4rem;
  cursor: pointer;
  white-space: nowrap;

  & svg {
    width: 1.2rem;
    height: 1.2rem;
  }

  &:hover {
    background-color: var(--button-bgcolor-default-hover);
  }

  &:focus-visible {
    background-color: var(--button-bgcolor-default-focus);
  }

  &:active {
    background-color: var(--button-bgcolor-default-active);
  }

  &:disabled {
    cursor: default;
    opacity: 0.6;
    background-color: var(--button-bgcolor-default);
    color: var(--color-grayscale-white);
  }

  &[data-variant='transparent'] {
    background-color: transparent;

    &:hover {
      background-color: var(--button-bgcolor-default-hover);
    }

    &:focus-visible {
      background-color: var(--button-bgcolor-default-focus);
    }

    &:active {
      background-color: var(--button-bgcolor-default-active);
    }

    &:disabled {
      background-color: transparent;
    }
  }

  &[data-variant='primary'] {
    background-color: var(--button-bgcolor-primary) !important;

    &:hover {
      background-color: var(--button-bgcolor-primary-hover) !important;
    }

    &:focus,
    &:focus-visible {
      background-color: var(--button-bgcolor-primary);
      outline: var(--button-bgcolor-primary) solid 0.15rem;
      outline-offset: 0.15rem;
    }

    &:active {
      background-color: var(--button-bgcolor-primary-active);
    }

    &:disabled {
      background-color: var(--button-bgcolor-primary);
    }

    & svg {
      fill: var(--white-default);
      stroke: var(--white-default);
    }

    & div {
      border-color: var(--white-default) transparent transparent transparent;
    }
  }

  &[data-variant='success'] {
    background-color: var(--button-bgcolor-success);

    &:hover {
      background-color: var(--button-bgcolor-success-hover);
    }

    &:focus-visible {
      background-color: var(--button-bgcolor-success-focus);
    }

    &:active {
      background-color: var(--button-bgcolor-success-active);
    }

    &:disabled {
      background-color: var(--button-bgcolor-success);
    }

    & svg {
      fill: var(--white-default);
      stroke: var(--white-default);
    }

    & div {
      border-color: var(--white-default) transparent transparent transparent;
    }
  }

  &[data-variant='warning'] {
    background-color: var(--button-bgcolor-warning);

    &:hover {
      background-color: var(--button-bgcolor-warning-hover);
    }

    &:focus-visible {
      background-color: var(--button-bgcolor-warning-focus);
    }

    &:active {
      background-color: var(--button-bgcolor-warning-active);
    }

    &:disabled {
      background-color: var(--button-bgcolor-warning);
    }

    & svg {
      fill: var(--white-default);
      stroke: var(--white-default);
    }

    & div {
      border-color: var(--white-default) transparent transparent transparent;
    }
  }

  &[data-variant='danger'] {
    background-color: var(--button-bgcolor-danger);

    &:hover {
      background-color: var(--button-bgcolor-danger-hover);
    }

    &:focus,
    &:focus-visible {
      background-color: var(--button-bgcolor-danger);
      outline: var(--button-bgcolor-danger) solid 0.15rem;
      outline-offset: 0.15rem;
    }

    &:active {
      background-color: var(--button-bgcolor-danger-active);
    }

    &:disabled {
      background-color: var(--button-bgcolor-danger);
    }

    & svg {
      fill: var(--white-default);
      stroke: var(--white-default);
    }

    & div {
      border-color: var(--white-default) transparent transparent transparent;
    }
  }

  &[data-variant='secondary'] {
    background-color: var(--button-bgcolor-secondary);
    color: var(--color-grayscale-woodsmoke);

    &:hover {
      background-color: var(--button-bgcolor-secondary-hover);
    }

    &:focus-visible {
      background-color: var(--button-bgcolor-secondary-focus);
      outline-color: var(--button-bgcolor-secondary-focus);
    }

    &:active {
      background-color: var(--button-bgcolor-secondary-active);
    }

    &:disabled {
      opacity: 1;
      background-color: var(--button-bgcolor-secondary);
    }
  }

  &[data-variant='dark'] {
    background-color: var(--color-grayscale-charleston);
    color: var(--color-grayscale-manatee);

    &:hover,
    &:focus-visible {
      background-color: var(--button-bgcolor-default-hover);
    }
  }

  &[data-variant='x-dark'] {
    background-color: var(--color-surfaces-bg-elevation-2);
    color: var(--color-grayscale-manatee);

    &:hover,
    &:focus-visible {
      background-color: var(--color-surfaces-bg-elevation-3);
    }
  }

  &[data-full='true'] {
    width: 100%;
    width: -moz-available;
    width: -webkit-fill-available;
    width: fill-available;
    height: 4rem;
  }
`;
