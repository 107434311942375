import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';

import { CalendarIcon, Contacts, Project, Settings } from '../../components/icons';
import { SpaceMenuItem } from './space-menu-item';
import { breakPoint } from '../theme';
import { uiStore } from '../../core/stores/ui-store';
import { useCurrentMember } from '../../hooks/use-current-member';
import { TasksIcon } from '../../components/icons/tasks-icon';

import type { Space } from '../entities/space';

type ProjectMenuProps = {
  space: Space;
  onClose: () => void;
};

export const SpaceMenu: React.FC<ProjectMenuProps> = observer(({ space, onClose }) => {
  let location = useLocation();
  const navigate = useNavigate();
  const currentMember = useCurrentMember();

  const { spaceId } = useParams();
  const pathname = location.pathname.split('/');

  const goTo = (href: string) => {
    onClose();
    navigate(href);
  };

  const isProject = pathname.includes('projects');

  return (
    <Container>
      <SpaceMenuItem
        id="menu-projects"
        onClick={() => goTo(`/space/${spaceId}`)}
        name={'Projects'}
        current={pathname.length === 3 || isProject}
        icon={<Project />}
      />

      <SpaceMenuItem
        id="space-tasks"
        onClick={() => goTo(`/space/${spaceId}/tasks`)}
        current={uiStore.currentLocation?.startsWith('space.tasks')}
        name={'Tasks'}
        icon={<TasksIcon />}
      />

      {space.flags?.isCalendarAccessible && (
        <SpaceMenuItem
          id="projects-calendar"
          onClick={() => goTo(`/space/${spaceId}/calendar`)}
          current={uiStore.currentLocation?.startsWith('space.calendar')}
          name={'Calendar'}
          icon={<CalendarIcon />}
          isDisabled={!(space.flags || {})['space.settings']}
        />
      )}

      {currentMember?.role !== 'guest' && (
        <SpaceMenuItem
          id="menu-contacts"
          onClick={() => goTo(`/space/${spaceId}/contacts`)}
          current={!!pathname.find((el) => el === 'contacts')}
          name={'Contacts'}
          icon={<Contacts />}
        />
      )}

      {/* TODO: uncomment when analytics feature is implemented*/}
      {/*<SpaceMenuItem
        id="menu-insights"
        onClick={() => goTo(`/space/${spaceId}/analytics`)}
        current={pathname[pathname.length - 1] === 'analytics'}
        name={'Analytics'}
        icon={<Analytics />}
      />*/}

      <SpaceMenuItem
        id="menu-settings"
        onClick={() => goTo(`/space/${spaceId}/settings`)}
        current={uiStore.currentLocation?.startsWith('space.settings')}
        name={'Settings'}
        icon={<Settings />}
        isDisabled={!(space.flags || {})['space.settings']}
      />
    </Container>
  );
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  padding: 0 1.6rem;
  flex: 1;
  order: 2;

  @media screen and (min-width: ${breakPoint.medium}px) {
    gap: 0.8rem;
    padding: 1.6rem;
    order: 1;
  }
`;
