import { ChannelTypeEnum, IMessage } from '@novu/headless';
import { computed, makeObservable } from 'mobx';
import { StepStatus } from './step';

type CommentCreated = {
  workflowId: NotificationType.CommentCreated;
  initiatorName: string;
  projectName: string;
  data: {
    assetUrl: string;
    targetName: string;
    commentId: string;
    assetId: string;
  };
};

export type StepStatusUpdated = {
  workflowId: NotificationType.StepStatusUpdated;
  initiatorName: string;
  projectName: string;
  data: {
    digestKey: string;
    stepName: string;
    stepUrl: string;
    projectUrl: string;
    oldStatus: StepStatus;
    newStatus: StepStatus;
    oldStatusTitle: string;
    newStatusTitle: string;
  };
};

export type StepAssign = {
  workflowId: NotificationType.StepAssigned | NotificationType.StepUnassigned;
  initiatorName: string;
  projectName: string;
  data: {
    digestKey: string;
    stepName: string;
    stepUrl: string;
  };
};

type StepApproved = {
  workflowId: NotificationType.StepApproved;
  initiatorName: string;
  projectName: string;
  data: {
    digestKey: string;
    stepName: string;
    stepUrl: string;
    projectUrl: string;
    approvalMessage: string;
  };
};

type MembershipRequest = {
  workflowId: NotificationType.RequestMembership;
  initiatorName: string;
  spaceName: string;
  data: {
    membersUrl: string;
  };
};

type MemberPromoted = {
  workflowId: NotificationType.MemberPromoted;
  initiatorName: string;
  spaceName: string;
  data: {
    spaceUrl: string;
    role: string;
  };
};

type TaskAssign = {
  workflowId: NotificationType.TaskAssigned;
  initiatorName: string;
  data: {
    targetName: string;
    dueDate: string;
    taskTitle: string;
    url: string;
    taskId: string;
  };
};

export enum NotificationType {
  RequestMembership = 'request-membership',
  MemberPromoted = 'member-promoted',
  StepStatusUpdated = 'step-status-updated',
  StepApproved = 'step-approved',
  CommentCreated = 'comment-created',
  StepAssigned = 'step-assigned',
  StepUnassigned = 'step-unassigned',
  TaskAssigned = 'task-assigned',
}

interface BaseNotification extends IMessage {
  payload:
    | CommentCreated
    | StepStatusUpdated
    | StepApproved
    | MembershipRequest
    | MemberPromoted
    | StepAssign
    | TaskAssign;
}

export class Notification implements BaseNotification {
  _id: string;
  _templateId: string;
  _environmentId: string;
  _organizationId: string;
  _notificationId: string;
  _subscriberId: string;
  template?: IMessage['template'];
  templateIdentifier?: string;
  content: IMessage['content'];
  channel: ChannelTypeEnum;
  seen: boolean;
  read: boolean;
  lastSeenDate: string;
  lastReadDate: string;
  createdAt: string;
  cta?: IMessage['cta'];
  _feedId: string;
  _layoutId?: string;
  actor?: IMessage['actor'];
  payload:
    | CommentCreated
    | StepStatusUpdated
    | StepApproved
    | MembershipRequest
    | MemberPromoted
    | StepAssign
    | TaskAssign;

  constructor(data: IMessage) {
    this._id = data._id;
    this._templateId = data._templateId;
    this._environmentId = data._environmentId;
    this._organizationId = data._organizationId;
    this._notificationId = data._notificationId;
    this._subscriberId = data._subscriberId;
    this.template = data.template;
    this.templateIdentifier = data.templateIdentifier;
    this.content = data.content;
    this.channel = data.channel;
    this.seen = data.seen;
    this.read = data.read;
    this.lastSeenDate = data.lastSeenDate || '';
    this.lastReadDate = data.lastReadDate || '';
    this.createdAt = data.createdAt;
    this.cta = data.cta;
    this._feedId = data._feedId || '';
    this._layoutId = data._layoutId;
    this.payload = data.payload as BaseNotification['payload'];
    this.actor = data.actor;

    makeObservable(this, {
      payload: true,
      seen: true,
      read: true,
      action: computed,
      url: computed,
      target: computed,
      parentTarget: computed,
    });
  }

  get action() {
    switch (this.payload.workflowId) {
      case NotificationType.RequestMembership:
        return 'requested membership in';
      case NotificationType.MemberPromoted:
        return 'promoted you to a';
      case NotificationType.StepStatusUpdated:
        return 'changed status of';
      case NotificationType.StepApproved:
        return 'approved';
      case NotificationType.CommentCreated:
        return 'commented on';
      case NotificationType.StepUnassigned:
        return 'unassigned you from the step';
      case NotificationType.StepAssigned:
        return 'assigned you to the step';
      case NotificationType.TaskAssigned:
        return `assigned you to the task ${this.payload.data.taskTitle || ''} ${
          this.payload.data.dueDate || ''
        }`.trim();

      default:
        return '';
    }
  }

  get url() {
    let url = '';
    const { workflowId, data } = this.payload;

    if (workflowId === NotificationType.CommentCreated) {
      const { assetUrl = '', commentId = '' } = data;
      url = `${assetUrl}?commentId=${commentId}`;
    } else if (workflowId === NotificationType.StepStatusUpdated) {
      const { stepUrl = '' } = data;
      url = stepUrl;
    } else if (workflowId === NotificationType.StepApproved) {
      const { stepUrl = '' } = data;
      url = stepUrl;
    } else if (workflowId === NotificationType.RequestMembership) {
      const { membersUrl = '' } = data;
      url = membersUrl;
    } else if (workflowId === NotificationType.MemberPromoted) {
      const { spaceUrl = '' } = data;
      url = spaceUrl;
    } else if (
      workflowId === NotificationType.StepAssigned ||
      workflowId === NotificationType.StepUnassigned
    ) {
      const { stepUrl = '' } = data;
      url = stepUrl;
    } else if (workflowId === NotificationType.TaskAssigned) {
      url = `${data.url || ''}?taskId=${data.taskId || ''}`;
    }
    const parsedUrl = new URL(url);
    return parsedUrl.pathname + parsedUrl.search;
  }

  get target() {
    const { workflowId, data } = this.payload;

    if (workflowId === NotificationType.CommentCreated) {
      const { targetName = '' } = data || {};
      return targetName;
    } else if (workflowId === NotificationType.StepStatusUpdated) {
      const { stepName = '' } = data || {};
      return stepName;
    } else if (workflowId === NotificationType.StepApproved) {
      const { stepName = '' } = data || {};
      return stepName;
    } else if (workflowId === NotificationType.RequestMembership) {
      const { spaceName = '' } = this.payload;
      return spaceName;
    } else if (workflowId === NotificationType.MemberPromoted) {
      const { role = '' } = data || {};
      return role;
    } else if (
      workflowId === NotificationType.StepAssigned ||
      workflowId === NotificationType.StepUnassigned
    ) {
      const { stepName = '' } = data || {};
      return stepName;
    } else if (workflowId === NotificationType.TaskAssigned) {
      const { targetName = '' } = data || {};
      return targetName;
    }

    return '';
  }

  get parentTarget() {
    const { workflowId } = this.payload;

    switch (workflowId) {
      case NotificationType.StepApproved:
      case NotificationType.StepStatusUpdated:
      case NotificationType.StepAssigned:
      case NotificationType.StepUnassigned:
      case NotificationType.CommentCreated:
        return this.payload.projectName;
      case NotificationType.RequestMembership:
      case NotificationType.MemberPromoted:
        return this.payload.spaceName;
      case NotificationType.TaskAssigned:
        return this.payload.data.targetName;
    }
  }
}
