import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';

import { SortableShotList } from './sortable-shot-list';
import { TableHeader, THead } from '../../../components/table/styled-shared-table';

import type { ShotListProps } from './shot-list';
import { DayStartStrip } from '../../schedule/components/day-start-strip';
import type { DayBreakStrip } from '../../schedule/models/types';

export const ShotListTable: React.FC<ShotListProps> = observer(
  ({ stripboard, project, strips }) => {
    const hasShots = !!project.shots.length;

    const firstDayBreakStrip = strips?.find((strip) => strip.type === 'dayBreak') as DayBreakStrip;

    return (
      <Table>
        <col
          style={{
            width: '8.5%',
          }}
        />
        <col
          style={{
            width: '37.5%',
          }}
        />
        <col
          style={{
            width: '10%',
          }}
        />
        <col
          style={{
            width: '10%',
          }}
        />
        <col
          style={{
            width: '10%',
          }}
        />
        <col
          style={{
            width: '10%',
          }}
        />
        <col
          style={{
            width: '5%',
          }}
        />
        <col
          style={{
            width: '5%',
          }}
        />
        <col
          style={{
            width: '4%',
          }}
        />

        <TableHeader>
          <THead>Shot</THead>

          <THead>Title / Description</THead>

          <THead>Shot size</THead>

          <THead>Angle</THead>

          <THead>Movement</THead>

          <THead>Lens</THead>

          <THead>FPS</THead>

          <THead>Est. Time</THead>

          <THead />
        </TableHeader>

        <tbody>
          {hasShots && (
            <>
              {firstDayBreakStrip && (
                <DayStartStrip stripboard={stripboard} strip={firstDayBreakStrip} isShotList />
              )}
              <SortableShotList stripboard={stripboard} strips={strips} project={project} />
            </>
          )}
        </tbody>
      </Table>
    );
  },
);

const Table = styled.table`
  border-collapse: separate;
  width: 100%;
  border-spacing: 0 0.8rem;
  table-layout: fixed;
  height: 1px; // for td to have 100% height
`;
