import React from 'react';
import styled from 'styled-components';
import prettyBytes from 'pretty-bytes';
import { FileTextIcon, Link2Icon } from '@radix-ui/react-icons';

import DocumentSize from '../../../assets/images/DocumentSize.svg';
import { FileTypePNG } from '../../../components/icons/FileTypePNG';
import { FileTypeAudio } from '../../../components/icons/FileTypeAudio';
import { FileTypeJPG } from '../../../components/icons/FileTypeJPG';
import { FileTypeMP4 } from '../../../components/icons/FileTypeMP4';
import { Asset } from '../../../app/entities/asset';
import { FILES_TYPES } from '../../../assets/enums/assetType.enum';
import { breakPoint } from '../../../app/theme';

type FileInfoProps = {
  file: Asset;
};

export const fileType = (asset: Asset): any => {
  const fileType = asset?.fileType || '';
  if (asset?.type === 'link') {
    return <Link2Icon />;
  } else if (asset?.type === 'note') {
    return <FileTextIcon />;
  } else if (fileType.includes(FILES_TYPES.APPLICATION)) {
    return <FileTypePNG />;
  } else if (fileType.includes(FILES_TYPES.AUDIO)) {
    return <FileTypeAudio />;
  } else if (fileType.includes(FILES_TYPES.IMAGE)) {
    return <FileTypeJPG />;
  } else if (fileType.includes(FILES_TYPES.VIDEO)) {
    return <FileTypeMP4 />;
  }
};

export const FileInfo: React.FC<FileInfoProps> = ({ file }) => (
  <Container>
    <FilesBodyItemDescr>
      <Logo>{fileType(file)}</Logo>

      <div style={{ textTransform: 'uppercase' }}>
        {file.type === 'link' ? 'link' : file.type === 'note' ? 'note' : file.extension}
      </div>
    </FilesBodyItemDescr>

    {!['link', 'note'].includes(file.type) && (
      <FilesBodyItemDescr>
        <Logo>
          <img src={DocumentSize} alt="" />
        </Logo>

        <div>{prettyBytes(file.size || 0)}</div>
      </FilesBodyItemDescr>
    )}
  </Container>
);

const Container = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 0.8rem;
`;

const FilesBodyItemDescr = styled.div`
  display: flex;
  color: #9d9d9d;
  font-size: 1.2rem;

  @media screen and (min-width: ${breakPoint.small}px) {
    font-size: 1.2rem;
  }
`;

const Logo = styled.div`
  margin-right: 0.4rem;

  @media screen and (min-width: ${breakPoint.small}px) {
    margin-right: 0.8rem;
  }
`;
