import { computed, makeObservable, observable, action } from 'mobx';
import { Drawer } from './drawer';

interface PageTitle {
  label: string;
  href?: string;
}

class UIStore {
  private _pageTitle?: PageTitle;
  private _contextDrawer: Drawer | undefined;
  private _showBack: boolean;
  private _action: Function | undefined;
  private _isDrawerOpen: boolean = false;
  private _isTaskPanelOpen: boolean = false;

  taskPanelTargetId: string | null = null;

  currentLocation: string;

  /**
   * goBack can be overriden by screens to allow for going back in the hierarchy.
   */
  goBack: (() => void) | null = null;

  constructor() {
    makeObservable<
      UIStore,
      | '_pageTitle'
      | '_contextDrawer'
      | '_showBack'
      | '_action'
      | '_isDrawerOpen'
      | '_isTaskPanelOpen'
    >(this, {
      _pageTitle: observable,
      _contextDrawer: observable,
      _showBack: observable,
      _action: observable,
      _isDrawerOpen: observable,
      _isTaskPanelOpen: observable,
      taskPanelTargetId: observable,
      currentLocation: observable,
      isDrawerOpen: computed,
      pageTitle: computed,
      contextDrawer: computed,
      showBack: computed,
      isTaskPanelOpen: computed,
      action: computed,
    });
  }

  set pageTitle(title: PageTitle | undefined) {
    this._pageTitle = title;
  }

  get pageTitle(): PageTitle | undefined {
    return this._pageTitle;
  }

  set contextDrawer(value: Drawer | undefined) {
    if (this._contextDrawer) {
      console.warn('A drawer is already registered on this screen.');
    }

    this._contextDrawer = value;
  }

  get isDrawerOpen(): boolean {
    return this._isDrawerOpen;
  }

  set isDrawerOpen(value: boolean) {
    this._isDrawerOpen = value;
  }

  get contextDrawer(): Drawer | undefined {
    return this._contextDrawer;
  }

  set showBack(value: boolean) {
    this._showBack = value;
  }

  get showBack(): boolean {
    return this._showBack;
  }

  set action(value: Function | undefined) {
    this._action = value;
  }

  get action(): Function | undefined {
    return this._action;
  }

  get isTaskPanelOpen(): boolean {
    return this._isTaskPanelOpen;
  }

  openTaskPanel(targetId: string) {
    this._isTaskPanelOpen = true;
    this.taskPanelTargetId = targetId;
  }

  closeTaskPanel() {
    this._isTaskPanelOpen = false;
    this.taskPanelTargetId = null;
  }
}

export const uiStore = new UIStore();
