import styled from 'styled-components';

export const StyledContainer = styled.div`
  user-select: none;
  display: flex;
  position: relative;
  cursor: pointer;

  svg {
    fill: var(--deprecated-white-grey-dark);
  }
`;

export const StyledLine = styled.div`
  width: 100%;
  display: flex;
  border-radius: 0.4rem;
  max-width: 12rem;
  transition: all 100ms ease-in-out;

  align-items: center;
  justify-content: start;
  height: 3rem;
  padding: 0 1.2rem;

  &:hover,
  &:focus-visible {
    background-color: var(--color-grayscale-arsenic);
    cursor: pointer;

    & > * {
      color: var(--color-grayscale-white);
    }
  }

  &[data-ellipsis='true'] {
    & > div {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &[data-disabled='true'] {
    &:hover {
      cursor: default;
      background-color: transparent;
    }
  }
`;

export const StyledEmpty = styled.div`
  font-size: 1.4rem;
  color: var(--color-grayscale-light-slate);
`;

export const DescriptiveOptionLabel = styled.div`
  display: flex;
  gap: 0.4rem;
  align-items: center;
  font-size: 1.4rem;
  font-weight: 400;
  color: var(--color-grayscale-white);
`;

export const DescriptiveOptionDescription = styled.div`
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2rem;
  color: var(--color-grayscale-light-slate);
`;

export const DescriptiveControl = styled.div`
  width: 100%;
  padding: 1.6rem;
  border-radius: 0.4rem;
  border: 1px solid var(--color-grayscale-trout);
  background: var(--color-grayscale-tuna);

  &:hover {
    border-color: var(--color-secondary);
    cursor: pointer;
  }

  ${DescriptiveOptionLabel} {
    color: var(--color-grayscale-white);
  }

  ${DescriptiveOptionDescription} {
    color: var(--color-grayscale-light-slate);
  }
`;

export const OptionsList = styled.ul`
  position: absolute;
  width: max-content;
  min-width: 100%;
  font-size: 1.4rem;
  color: var(--color-grayscale-white);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: var(--card-border-radius);
  animation: fadein 0.2s;
  z-index: 99;
  overflow-y: auto;

  list-style: none;
  max-height: 24rem;
  background: var(--color-grayscale-charleston);

  & > hr {
    width: 100%;
  }

  & > li {
    text-align: start;
    width: 100%;
    border-radius: 0.4rem;
    cursor: pointer;

    svg {
      height: 2.4rem;
      width: 2.4rem;
    }

    &[data-active='true'] {
      background-color: var(--color-grayscale-arsenic);

      ${DescriptiveOptionDescription} {
        color: var(--color-grayscale-cadet);
      }
    }

    &:hover {
      background-color: var(--color-grayscale-arsenic);

      ${DescriptiveOptionDescription} {
        color: var(--color-grayscale-ghost);
      }
    }
  }

  @keyframes fadein {
    0% {
      opacity: 0;
      transform: scale(0.95);
    }

    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
`;
