import React, { useState } from 'react';
import styled from 'styled-components';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

import { InfoTextLine } from '../texts/Texts';

import type { SelectDropdownProps as SelectBaseDropdownProps } from '.';
import { DefaultContentMenu } from './content-menu/default-content-menu';
import { GroupContentMenu } from './content-menu/group-content-menu';

type SelectDropdownProps = SelectBaseDropdownProps & {
  isMulti?: boolean;
  selectedItemsLabel?: string;
  canOpen?: boolean;
  showCheckbox?: boolean;
};

export const SelectDropdown: React.FC<SelectDropdownProps> = ({
  title,
  selectedItemsLabel,
  items,
  groups,
  onCreate,
  isMulti,
  trigger,
  canOpen,
  showCheckbox = true,
  placeholder,
  disabled,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleCreate = async (value: string) => {
    await onCreate?.(value);
    handleOpenChange(false);
  };

  const handleOpenChange = (isOpen: boolean) => {
    if (canOpen !== undefined && isOpen) {
      setIsOpen(canOpen);
      return;
    }

    setIsOpen(isOpen);
  };

  return (
    <Container modal={true} open={isOpen} onOpenChange={handleOpenChange}>
      <DropdownMenu.Trigger disabled={disabled} asChild>
        {trigger?.(selectedItemsLabel) || (
          <Line data-ellipsis={!isMulti} tabIndex={0} className={'trigger'}>
            {selectedItemsLabel ? (
              <InfoTextLine value={selectedItemsLabel as string} />
            ) : (
              <StyledEmpty>{title}</StyledEmpty>
            )}
          </Line>
        )}
      </DropdownMenu.Trigger>

      <DropdownMenu.Portal>
        {items ? (
          <DefaultContentMenu
            items={items}
            {...(onCreate && { onCreate: handleCreate })}
            showCheckbox={showCheckbox}
            placeholder={placeholder}
          />
        ) : (
          <GroupContentMenu items={groups} showCheckbox={showCheckbox} placeholder={placeholder} />
        )}
      </DropdownMenu.Portal>
    </Container>
  );
};

const Container = styled(DropdownMenu.Root)`
  margin: -1.2rem;
  width: 100%;

  svg {
    height: 1.5rem;
    width: 1.5rem;
    margin-left: 0.5rem;
  }
`;

const Line = styled.div`
  align-items: center;
  justify-content: start;
  width: 100%;
  padding: 0.8rem 1.2rem;
  line-height: 1.6rem;
  max-width: 100%;
  overflow: hidden;
  border-radius: 0.4rem;

  &[data-ellipsis='true'] {
    & > div {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &:hover,
  &:focus-visible {
    background-color: var(--color-grayscale-arsenic);
    cursor: pointer;

    & div {
      color: var(--color-grayscale-white);
    }

    svg {
      stroke: var(--color-grayscale-white);
    }
  }
`;

const StyledEmpty = styled.div`
  font-size: 1.4rem;
  color: var(--color-grayscale-light-slate);
`;
