import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { TrashIcon } from '@radix-ui/react-icons';
import * as yup from 'yup';

import { StatusSelector } from '../../process/components/status-selector';
import { DropdownMenu } from '../../../components/modals/dropdown-menu/DropdownMenu';
import { DropdownTrigger } from '../../projects/components/styled-project-components';
import { DeleteDialog } from '../../../components/dialogs/DeleteDialog';
import { PrioritySelector } from '../../process/components/priority-selector';
import { StepSelector } from './step-selector';
import { TaskDateSelector } from './task-date-selector';
import { InlineEditableText } from '../../../components/editable-text/inline-editable-text';
import { TaskAssigneeSelector } from './task-assignee-selector';
import { authStore } from '../../../core/stores/auth-store';
import { useCurrentMember } from '../../../hooks/use-current-member';
import { useCurrentProject } from '../../../hooks/use-current-project';
import { Strip, Left, Wrapper, TitleWrapper } from './styled-tasks-components';
import { Task, TaskStatus } from '../../../app/entities/task';
import { differenceInCalendarDays } from 'date-fns';
import { ProjectSelector } from './project-selector';

type TaskItemProps = {
  task: Task;
};

export const TaskItem: React.FC<TaskItemProps> = observer(({ task }) => {
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);

  const currentMember = useCurrentMember();
  const currentProject = useCurrentProject();

  const dueDateStatus = useRef<'warning' | 'danger'>();

  const isViewOnly =
    !currentMember || (currentMember.role === 'guest' && currentMember._id !== task.assigneeId);

  useEffect(() => {
    if (!task.dueDate || [TaskStatus.Done, TaskStatus.Canceled].includes(task.status)) {
      return;
    }

    if (task.dueDate) {
      const today = new Date();
      const dueDate = new Date(task.dueDate);

      const dateDifference = differenceInCalendarDays(dueDate, today);

      if (dateDifference <= 0) {
        dueDateStatus.current = 'danger';
      } else if (dateDifference < 4) {
        dueDateStatus.current = 'warning';
      }
    }
  }, [task.dueDate, task.status]);

  const handleOpenDelete = async () => {
    if (isViewOnly) {
      return authStore.setAuthorized(false);
    }

    setDeleteModalIsOpen(true);
  };

  const menuItems: React.ComponentProps<typeof DropdownMenu>['items'] = [
    {
      title: 'Delete task',
      icon: <TrashIcon />,
      type: 'danger' as const,
      onSelect: handleOpenDelete,
    },
  ];

  const titleValidatorSchema = yup.string().max(128, '');

  return (
    <Strip>
      <Wrapper data-full-width="true">
        <Left>
          <StatusSelector
            isViewOnly={isViewOnly}
            disableTooltip={isViewOnly}
            status={task.status}
            onChange={(status) => task.update({ status })}
            statusMap={Task.StatusMapper}
          />

          <PrioritySelector
            isViewOnly={isViewOnly}
            priority={task.priority}
            onChange={(priority) => task.update({ priority })}
          />

          <TaskAssigneeSelector
            isViewOnly={isViewOnly}
            project={task.project}
            selectedMemberId={task.assigneeId}
            onChange={(assigneeId) => {
              task.update({
                assigneeId: assigneeId ? assigneeId : null,
                projectId: task.projectId,
              });
            }}
            iconOnly
          />
        </Left>

        <TitleWrapper>
          <InlineEditableText
            disabled={isViewOnly}
            validationType="onChange"
            schema={titleValidatorSchema}
            value={task.title}
            onSubmit={(title) => task.update({ title })}
          />
        </TitleWrapper>
      </Wrapper>

      <Wrapper>
        {!currentProject && (
          <ProjectSelector
            size="small"
            onChange={(projectId) => task.update({ projectId, stepId: null })}
            selectedProjectId={task.project?._id}
            isViewOnly={isViewOnly}
          />
        )}

        <StepSelector
          isViewOnly={isViewOnly}
          project={task.project}
          onChange={(stepId) => task.update({ stepId, projectId: task.project?._id })}
          selectedStepId={task.stepId || undefined}
        />

        <TaskDateSelector
          isViewOnly={isViewOnly}
          date={task.dueDate}
          onUpdate={(date) => task.update({ dueDate: date })}
          status={task.status}
        />

        <DropdownMenu
          items={menuItems}
          trigger={<DropdownTrigger data-size="small">⋮</DropdownTrigger>}
        />
      </Wrapper>

      {deleteModalIsOpen && (
        <DeleteDialog
          title="Delete task"
          text={`The task will be deleted completely from the project.`}
          onCancel={() => setDeleteModalIsOpen(false)}
          onSubmit={() => {
            task.delete();
          }}
        />
      )}
    </Strip>
  );
});
