import styled from 'styled-components';

export const Strip = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.8rem 1.6rem;
  gap: 0.8rem;

  &:not(:last-child) {
    border-bottom: 0.1rem solid var(--color-surfaces-bg-elevation-2);
  }

  &[data-header='true'] {
    background: var(--color-surfaces-bg-elevation-1);
    border: none;
  }
`;

export const Left = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & > div:last-child {
    margin-inline-end: 0.4rem;
    margin-inline-start: 0.8rem;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.8rem;

  &[data-full-width='true'] {
    flex: 1;
  }
`;

export const TitleWrapper = styled.div`
  width: 100%;

  & > div {
    margin-inline-start: -0.8rem;
    width: 100%;

    & > input {
      width: 100%;
      padding: 0.2rem 0.8rem;
      height: 2.4rem;
      font-size: 1.4rem;
      font-weight: 400;
      color: var(--color-texts-high-contrast);

      &:disabled {
        color: var(--color-texts-high-contrast);
      }
    }
  }
`;

export const CustomAvatar = styled.div`
  display: flex;
  min-width: 2.4rem;
  min-height: 2.4rem;
  max-width: 2.4rem;
  max-height: 2.4rem;
  justify-content: center;
  align-items: center;
  border: 1px var(--color-texts-low-contrast) dashed;
  border-radius: 0.4rem;
  padding: 0.4rem;
  color: var(--color-texts-medium-contrast);
`;

export const TaskTrigger = styled.div`
  display: flex;
  min-width: 0;
  height: 100%;
  padding: 0.6rem 0.8rem;
  background: var(--color-surfaces-bg-elevation-2);
  align-items: center;
  border-radius: 0.4rem;
  border: 0.1rem solid transparent;
  gap: 0.8rem;

  font-size: 1.2rem;
  font-weight: 400;

  & > span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &[data-placeholder='true'] {
    flex-direction: row;
    color: var(--color-texts-low-contrast);
    background: transparent;
    border: 0.1rem dashed var(--color-texts-low-contrast);
  }

  &[data-size='small'] {
    height: 2.4rem;
    padding: 0 0.4rem;
  }

  &:hover,
  &:focus-visible {
    background: var(--color-surfaces-bg-elevation-4);
    border-color: var(--color-surfaces-bg-elevation-4);
    cursor: pointer;

    &[data-disabled='true'] {
      cursor: default;
      border-color: var(--color-surfaces-bg-elevation-2);
      background: var(--color-surfaces-bg-elevation-2);

      &[data-placeholder='true'] {
        color: var(--color-texts-low-contrast);
        border-color: var(--color-texts-low-contrast);
        background: transparent;
      }

      & > span,
      & > svg {
        color: var(--color-texts-low-contrast);
      }
    }

    & > span,
    & > svg {
      color: var(--color-texts-high-contrast);
    }
  }

  &[data-disabled='true'] {
    cursor: default;

    > div > span {
      color: var(--color-grayscale-shuttle);
    }

    &:hover,
    &:focus-visible {
      background-color: transparent !important;
    }
  }
`;
