import React from 'react';
import styled from 'styled-components';
import { useDropzone } from 'react-dropzone';
import { observer } from 'mobx-react-lite';

import { FileTile } from '../../features/assets/components/file-tile';
import { FileListItem } from '../../features/assets/components/file-list-item';
import { FileDropzone } from '../../features/assets/components/FileDropzone';
import { DropArrow } from '../icons/DropArrow';
import { AssetGrid } from '../grid';
import { useSelection } from '../../core/contexts/selection.context';
import { sortingFunction } from '../../assets/enums/assetType.enum';
import { authStore } from '../../core/stores/auth-store';

import type { Asset } from '../../app/entities/asset';
import { ListView, ListViewHeader } from '../list-view/list-view';
import { FolderItem } from '../../features/assets/components/folder-item';

type CommonFileProps = {
  files: Asset[];
  sortBy: string;
  onAddFiles: (files: File[]) => Promise<void>;
  assetsRouteTo: (asset: string, assetId: string) => void;
  displayLayout?: 'grid' | 'list';
};

export const FileList: React.FC<CommonFileProps> = observer(
  ({ files, assetsRouteTo, onAddFiles, sortBy, displayLayout = 'grid' }) => {
    const { elements, clear } = useSelection();

    const currentUser = authStore.currentUser;

    const onDrop = (files: File[]) => {
      onAddFiles(files);
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDrop,
      noClick: true,
    });

    const hasFiles = !!files.length;

    const folders = files.filter((file) => file.type === 'folder');
    const nonFolders = files.filter((file) => file.type !== 'folder');

    return (
      <React.Fragment>
        <DropFileOverlay {...getRootProps()}>
          <input {...getInputProps()} />

          <div onClick={clear} style={{ height: '100%' }}>
            {hasFiles ? (
              displayLayout === 'grid' ? (
                <>
                  {!!folders.length && (
                    <FilesContainer>
                      {folders
                        .slice()
                        .sort(sortingFunction[sortBy])
                        .map((folder) => (
                          <FolderItem
                            key={folder._id}
                            folder={folder}
                            assetsRouteTo={assetsRouteTo}
                          />
                        ))}
                    </FilesContainer>
                  )}

                  <FilesContainer>
                    {nonFolders
                      .slice()
                      .sort(sortingFunction[sortBy])
                      .map((file) => (
                        <FileTile
                          key={file._id}
                          asset={file}
                          assetsRouteTo={assetsRouteTo}
                          isSelected={elements.includes(file._id)}
                          currentUser={currentUser}
                        />
                      ))}
                  </FilesContainer>
                </>
              ) : (
                <ListView>
                  <ListViewHeader>
                    <th>Name</th>
                    <th>Type</th>
                    <th>Size</th>
                    <th>Upload Date</th>
                    <th>Creator</th>
                    <th>Comments</th>
                    <th>Last commented</th>
                    <th style={{ width: '100%', overflow: 'hidden' }} />
                    <th />
                  </ListViewHeader>

                  <tbody>
                    {files
                      .slice()
                      .sort(sortingFunction[sortBy])
                      .map((file) => (
                        <FileListItem
                          key={file._id}
                          assetsRouteTo={assetsRouteTo}
                          asset={file}
                          currentUser={currentUser}
                          isSelected={elements.includes(file._id)}
                        />
                      ))}
                  </tbody>
                </ListView>
              )
            ) : (
              <FileDropzone handleSubmitFile={onAddFiles} />
            )}
          </div>
        </DropFileOverlay>

        {isDragActive && (
          <DropZone>
            <DropArrow />

            <Content>Drop files to upload</Content>
          </DropZone>
        )}
      </React.Fragment>
    );
  },
);

const DropFileOverlay = styled.div`
  height: 100%;
`;

const FilesContainer = styled(AssetGrid)`
  position: relative;
  padding: 1.6rem;
`;

const Content = styled.div`
  color: white;
  font-size: 1.6rem;
  text-align: center;
`;

const DropZone = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  position: absolute;
  bottom: 9.6rem;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  left: 0;
  right: 0;
  width: fit-content;
`;
