import React from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { format } from 'date-fns';
import styled from 'styled-components';

import { Space } from '../../../../app/entities/space';
import { StorageProgress } from '../../../settings/components/storage-progress';
import { dateShort } from '../../../../assets/contants/dates-fns';
import { SeatsProgress } from '../../../settings/components/seats-progress';
import { Description, Header } from './styled-billing';

type StorageBillingSectionProps = {
  space: Space;
};

export const StorageBillingSection: React.FC<StorageBillingSectionProps> = observer(({ space }) => (
  <Section>
    <HeaderWrapper>
      <Header>
        {space?.billingDetails?.nextRenewal && (
          <>
            <h3>{format(new Date(space.billingDetails.nextRenewal), dateShort)}</h3>

            <Description>Next invoice date</Description>
          </>
        )}
      </Header>

      {space.hasSubscription() && space.subscription?.provider === 'paddle' && (
        <Action to={'./history'}>View billing history</Action>
      )}
    </HeaderWrapper>

    <ItemsContainer style={{ paddingBottom: '1.6rem' }}>
      <ResourcesWrapper>
        <h3>Storage</h3>

        <StorageProgress space={space} />
      </ResourcesWrapper>
    </ItemsContainer>

    {space.hasSubscription() && !space.isSelfServing && (
      <ItemsContainer>
        <ResourcesWrapper>
          <h3>Seats</h3>

          <SeatsProgress space={space} />
        </ResourcesWrapper>
      </ItemsContainer>
    )}
  </Section>
));

const Action = styled(Link)`
  all: unset;
  color: var(--color-primary-crayola);
  font-size: 1.6rem;
  font-weight: 400;
  width: auto;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  user-select: none;
  padding-bottom: 1.6rem;
  border-bottom: 1px solid var(--color-grayscale-trout);
`;

const ResourcesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  user-select: none;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  padding: 2.4rem;
  border-radius: 0.4rem;
  border: 1px solid var(--color-grayscale-trout);
`;

const ItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;

  &:not(:last-child) {
    border-bottom: 1px solid var(--color-grayscale-trout);
  }
`;
