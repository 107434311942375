import React from 'react';
import { observer } from 'mobx-react-lite';
import { ClipboardCopyIcon } from '@radix-ui/react-icons';
import styled from 'styled-components';

import { SingleSelectDropdown } from '../../../components/select-dropdown';
import { Project } from '../../../app/entities/project';
import { TaskTrigger } from './styled-tasks-components';

type StepSelectorProps = {
  // Selected project or predefined project
  project?: Project;
  onChange: (value: string) => void;
  selectedStepId: string | undefined;

  // Step is view only if you try to create a task from a step page
  isViewOnly?: boolean;
  withIcon?: boolean;
  placeholder?: string;
};

export const StepSelector: React.FC<StepSelectorProps> = observer(
  ({ project, onChange, selectedStepId, withIcon, placeholder = 'Select step', isViewOnly }) => {
    const options: React.ComponentProps<typeof SingleSelectDropdown>['groups'] = project
      ? project.stages?.map((stage) => ({
          title: stage.name,
          items: stage.steps.map((step) => ({
            label: step.displayName,
            value: step._id,
          })),
        }))
      : [];

    const handleStepChange = (value?: string) => {
      onChange(value || '');
    };

    return (
      <Container>
        <SingleSelectDropdown
          trigger={(label) => (
            <TaskTrigger data-placeholder={!label} data-disabled={isViewOnly} tabIndex={0}>
              {label ? (
                <StepLabel>
                  {withIcon && (
                    <ClipboardCopyIcon
                      style={{
                        color: isViewOnly
                          ? 'var(--color-grayscale-shuttle)'
                          : 'var(--color-texts-high-contrast)',
                      }}
                    />
                  )}

                  <InfoTextLine tabIndex={0}>{label}</InfoTextLine>
                </StepLabel>
              ) : (
                <>
                  <ClipboardCopyIcon />

                  <span>{placeholder}</span>
                </>
              )}
            </TaskTrigger>
          )}
          title="Step"
          value={selectedStepId}
          groups={options}
          onValueChange={handleStepChange}
          canOpen={!isViewOnly && !!project}
          disabled={isViewOnly || !project}
        />
      </Container>
    );
  },
);

const Container = styled.div`
  height: 3.2rem;
  height: 2.4rem;
  width: fit-content;
  overflow: hidden;
`;

const InfoTextLine = styled.span`
  font-size: 1.4rem;
  font-weight: 400;
  color: var(--color-texts-high-contrast);
`;

const StepLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
`;
