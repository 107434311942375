import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';

import { HeaderLeft } from './header-left';
import { StepStatusSelector } from '../../process/components/step-status-selector';
import { useResponsive } from '../../../hooks/useResponsive';
import { uiStore } from '../../../core/stores/ui-store';
import { ClipboardIcon } from '@radix-ui/react-icons';
import { KanbanCardAction } from '../../kanban/components/kanban-card-action';
import { Step } from '../../../app/entities/step';

interface ScreenHeaderProps {
  stepId?: string;
  title: string;
  dueDate?: string;
  startDate?: string;
  actions?: React.ReactNode[];
  canValidateStep?: boolean;
  breadcrumb?: React.ReactNode;
  menu?: React.ReactNode;
  handleUpdateTimeFrame?: (values: {
    dueDate?: string | undefined;
    startDate?: string | undefined;
  }) => void;
}

export const ScreenHeader: React.FC<ScreenHeaderProps> = observer(
  ({
    stepId,
    title,
    dueDate,
    startDate,
    actions,
    canValidateStep = true,
    breadcrumb,
    menu,
    handleUpdateTimeFrame,
  }) => {
    const { isDesktop } = useResponsive();

    const step = Step.getOne(stepId!)!;

    const renderLeftComponent = () => {
      if (isDesktop && breadcrumb) {
        return breadcrumb;
      }

      return (
        <HeaderLeft
          title={title}
          dueDate={isDesktop ? dueDate : undefined}
          startDate={isDesktop ? startDate : undefined}
          onUpdate={handleUpdateTimeFrame!}
        />
      );
    };

    const handleShowTasksPanel = (e: any) => {
      e.stopPropagation();
      e.preventDefault();

      uiStore.isTaskPanelOpen ? uiStore.closeTaskPanel() : uiStore.openTaskPanel(stepId!);
    };

    return (
      <Header>
        {renderLeftComponent()}

        <HeaderDocument>
          {actions && actions.length > 0 && actions.map((action) => action)}

          {isDesktop && <>{canValidateStep && stepId && <StepStatusSelector stepId={stepId} />}</>}

          <KanbanCardAction
            icon={<ClipboardIcon />}
            onClick={handleShowTasksPanel}
            text={`${step.completedTask}/${step.activeTasksCount}`}
          />

          {menu}
        </HeaderDocument>
      </Header>
    );
  },
);

const Header = styled.header`
  height: 4rem;
  min-height: 4rem;
  border-bottom: 1px var(--content-border-color) solid;
  color: #e3e4e7;
  font-size: 1.3rem;
  font-weight: 600;
  padding: 0 1.6rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  justify-content: space-between;
  cursor: default;
`;

const HeaderDocument = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 1.2rem;
`;
