import React, { useEffect, useState } from 'react';
import { ClipboardIcon } from '@radix-ui/react-icons';
import { observer } from 'mobx-react-lite';

import { Header } from './project.page';
import { useCurrentProject } from '../../hooks/use-current-project';
import { uiStore } from '../../core/stores/ui-store';

import { TaskList } from '../../features/tasks/components/tasks-list';
import { ElevatedButton } from '../../components/buttons';
import { SaveTaskModal } from '../../features/tasks/components/save-task.modal';
import { PagePlaceholder } from '../../components/page-placeholder/page-placeholder';

export const ProjectTasksRoute: React.FC = observer(() => {
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const project = useCurrentProject()!;

  useEffect(() => {
    uiStore.currentLocation = 'project.tasks';

    return () => {
      uiStore.currentLocation = '';
    };
  }, []);

  return (
    <>
      <Header>
        <span>Tasks</span>

        <ElevatedButton
          icon={<ClipboardIcon style={{ stroke: 'unset' }} />}
          text="Create task"
          onClick={() => setIsCreateModalOpen(true)}
        />
      </Header>

      {project.tasks.length === 0 ? (
        <PagePlaceholder
          title="You're off to a clean start!"
          description="Start by creating a task to keep things organized and on track."
          fullWidth
          backgroundImgUrl="/images/order-from-chaos.svg"
        />
      ) : (
        <TaskList tasks={project.tasks} taskLevel="project" />
      )}

      {isCreateModalOpen && (
        <SaveTaskModal
          onClose={() => setIsCreateModalOpen(false)}
          title="Create task"
          project={project}
        />
      )}
    </>
  );
});
