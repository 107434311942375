import React from 'react';

export const MovieIcon = ({ fill }: { fill?: string }) => (
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.8334 14.5H3.16671C2.81306 14.5001 2.47386 14.3597 2.22379 14.1096C1.97372 13.8595 1.83328 13.5203 1.83337 13.1667V3.83333C1.83328 3.47968 1.97372 3.14049 2.22379 2.89042C2.47386 2.64035 2.81306 2.4999 3.16671 2.5H13.8334C14.187 2.4999 14.5262 2.64035 14.7763 2.89042C15.0264 3.14049 15.1668 3.47968 15.1667 3.83333V13.1667C15.1668 13.5203 15.0264 13.8595 14.7763 14.1096C14.5262 14.3597 14.187 14.5001 13.8334 14.5Z"
      stroke={fill || '#959BAB'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1.83337 5.83333H15.1667"
      stroke={fill || '#959BAB'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.5 5.83333L5.83333 2.5"
      stroke={fill || '#959BAB'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.83337 5.83333L9.16671 2.5"
      stroke={fill || '#959BAB'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.1666 5.83333L12.5 2.5"
      stroke={fill || '#959BAB'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.24829 11.3995V8.93409C7.24833 8.77923 7.33099 8.63615 7.46513 8.55877C7.59927 8.48138 7.76451 8.48145 7.89858 8.55895L10.0318 9.79167C10.1658 9.86908 10.2483 10.0121 10.2483 10.1668C10.2483 10.3215 10.1658 10.4645 10.0318 10.5419L7.89859 11.7747C7.76451 11.8522 7.59927 11.8522 7.46513 11.7749C7.33098 11.6975 7.24832 11.5544 7.24829 11.3995Z"
      fill={fill || '#959BAB'}
    />
  </svg>
);
