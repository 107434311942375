import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';

import { ElevatedButton } from '../../../components/buttons';
import { DeleteProjectModal } from '../modals/delete-project.modal';
import { Project } from '../../../app/entities/project';
import { tabPool } from '../../tabs/models/tabs-pool';

type DangerZoneProps = {
  project?: Project;
};

export const DangerZone: React.FC<DangerZoneProps> = observer(({ project }) => {
  const { spaceId } = useParams() as { spaceId: string };

  const navigate = useNavigate();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

  const handleOnDeleteClick = () => {
    setIsDeleteModalOpen(true);
  };

  const handleSuccess = () => {
    if (!project) {
      return;
    }
    const href = `/space/${spaceId}`;

    const tab = tabPool.elements.find((tab) => tab.key === project?._id);
    if (tab) {
      tab.close();
    }
    navigate(href);
  };

  return (
    <Container>
      <Header>Danger Zone</Header>

      <ContentWrapper>
        <SettingsCard>
          <Content>
            <TitleWrapper>
              <Title>Transfer ownership</Title>
              <ComingSoon>Coming soon</ComingSoon>
            </TitleWrapper>
            <Description>Transfer this project to another space.</Description>
          </Content>

          <ElevatedButton variant="default" text="Transfer" disabled />
        </SettingsCard>

        <SettingsCard>
          <Content>
            <TitleWrapper>
              <Title>Archive this project</Title>
              <ComingSoon>Coming soon</ComingSoon>
            </TitleWrapper>
            <Description>Mark this project as archived and read-only.</Description>
          </Content>

          <ElevatedButton variant="default" text="Archive this project" disabled />
        </SettingsCard>

        <SettingsCard>
          <Content>
            <Title>Delete this project</Title>
            <Description>
              Once you delete a project, there is no coming back. Please be certain.
            </Description>
          </Content>

          <ElevatedButton
            variant="danger"
            onClick={handleOnDeleteClick}
            text="Delete this project"
          />
        </SettingsCard>
      </ContentWrapper>

      {project && isDeleteModalOpen && (
        <DeleteProjectModal
          onCancel={() => setIsDeleteModalOpen(false)}
          onSuccess={handleSuccess}
          projectId={project?._id}
        />
      )}
    </Container>
  );
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  max-width: 64.8rem;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const SettingsCard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.6rem 0;

  &:first-child {
    padding-top: 0;
  }

  &:not(:last-child) {
    border-bottom: 0.1rem solid var(--color-grey-7-5);
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`;

const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1.6rem;
`;

const Title = styled.h6`
  font-size: 1.6rem;
  font-weight: 500;
  color: var(--color-grey-1);
`;

const Description = styled.p`
  font-size: 1.4rem;
  font-weight: 400;
  color: var(--color-grey-1);
`;

const Header = styled.h3`
  padding-bottom: 1.6rem;
  border-bottom: 0.1rem solid var(--color-grey-7-5);
`;

const ComingSoon = styled.div`
  border-radius: var(--border-radius-medium);
  background: var(--color-grey-8);
  color: #f7f8f877;
  font-size: 1rem;
  text-transform: uppercase;
  padding: 0.4rem 0.8rem;
`;
