import React from 'react';
import styled from 'styled-components';

interface EmptyDrawerPlaceholderProps {
  text: string | React.ReactNode;
  icon: React.ReactNode;
}

export const EmptyDrawerPlaceholder: React.FC<EmptyDrawerPlaceholderProps> = ({ icon, text }) => (
  <Container>
    {icon}

    {typeof text === 'string' ? <Text>{text}</Text> : text}
  </Container>
);

const Container = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  svg {
    height: 6.4rem;
    opacity: 0.6;
    color: var(--color-texts-low-contrast);
  }
`;

const Text = styled.p`
  max-width: 70%;
  text-align: center;
  font-size: 1.4rem;
  opacity: 0.6;
  font-weight: 400;
  margin-top: 1.6rem;
  color: var(--color-texts-low-contrast);
`;
