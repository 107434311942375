import React from 'react';

export const TasksIcon: React.FC = () => (
  <svg width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 6H9.5V7C9.5 8.10457 10.3954 9 11.5 9H17.5C18.6046 9 19.5 8.10457 19.5 7V6H21C22.1046 6 23 6.89543 23 8V22C23 23.1046 22.1046 24 21 24H8C6.89543 24 6 23.1046 6 22V8C6 6.89543 6.89543 6 8 6ZM18.2204 14.6004C18.5932 14.2343 18.5932 13.6407 18.2204 13.2746C17.8476 12.9085 17.2433 12.9085 16.8705 13.2746L14.3636 15.7367L13.1295 14.5246C12.7567 14.1585 12.1524 14.1585 11.7796 14.5246C11.4068 14.8907 11.4068 15.4843 11.7796 15.8504L13.6887 17.7254C14.0614 18.0915 14.6658 18.0915 15.0386 17.7254L18.2204 14.6004Z"
      fill="currentColor"
    />
    <rect x="10.5" y="4" width="8" height="4" rx="1.5" fill="#3C404A" />
  </svg>
);
