import React, { ReactElement } from 'react';
import { OutlineArrowRight } from '@producer-io/ui-kit';
import styled from 'styled-components';
import { BackButton } from '../../settings/components/settings-page-layout';

type DescriptionHeaderProps = {
  title: string;
  description?: string;
  actions?: React.ReactNode;
  onBack?: () => void;
};

export const DescriptionHeader: React.FC<DescriptionHeaderProps> = ({
  title,
  description,
  actions,
  onBack,
}) => (
  <Container>
    {title && (
      <Header>
        <TitleContainer>
          {onBack && (
            <BackButton onClick={onBack}>
              <OutlineArrowRight />
            </BackButton>
          )}

          <Title>{title}</Title>
        </TitleContainer>

        {actions && <Actions>{actions}</Actions>}
      </Header>
    )}

    {description && (
      <Description
        dangerouslySetInnerHTML={{
          __html: description,
        }}
      />
    )}
  </Container>
);

const Container = styled.div`
  width: 100%;
  margin-bottom: 2.4rem;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;

  &:not(:first-child) {
    margin-top: 5.6rem;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px solid #31343c;
  padding-bottom: 0.8rem;
  align-items: center;
  user-select: none;
`;

const TitleContainer = styled.div`
  display: flex;
  gap: 0.8rem;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.h2`
  font-style: normal;
  line-height: 4rem;
  color: #e6edf3;
`;

const Description = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2.4rem;
  color: var(--color-grayscale-light-slate);
  max-width: 60rem;
  overflow-wrap: break-word;

  a {
    color: white;
    text-decoration: underline;
  }
`;

const Actions = styled.div`
  display: flex;
`;
