import React, { useState } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { Pencil1Icon, TrashIcon } from '@radix-ui/react-icons';
import { useNavigate } from 'react-router-dom';

import { CharacterCards } from '../components/character-cards';
import { CharacterListPlaceholder } from '../components/character-list-placeholder';
import { ScreenHeader } from '../../projects/components/screen-header';
import { ScreenContainer } from '../../projects/components/styled-project-components';
import { IconButton } from '../../../components/buttons';
import { Plus } from '../../../components/icons';
import { entityPool } from '../../../core/engine/engine';
import { Project } from '../../../app/entities/project';
import { DropdownMenu } from '../../../components/modals/dropdown-menu/DropdownMenu';
import { RenameStepModal } from '../../process/components/rename-step.modal';
import { CreateCharacterDrawer } from '../components/create-character.drawer';

import type { ScreenProps } from '../../projects/models/types';
import { StepDrawer } from '../../projects/components/step.drawer';
import { DeleteDialog } from '../../../components/dialogs/DeleteDialog';
import { uiStore } from '../../../core/stores/ui-store';
import { TaskPanel } from '../../tasks/components/task.panel';

export const CharacterListScreen: React.FC<ScreenProps> = observer(({ projectId, step }) => {
  const navigate = useNavigate();
  const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isDeleteStepModalOpen, setIsDeleteStepModalOpen] = useState(false);

  const project = entityPool.getEntity<Project>(projectId)!;

  const hasCharacters = !!project.characters.length;

  const items = [
    {
      title: 'Rename step',
      icon: <Pencil1Icon />,
      onSelect: () => setIsRenameModalOpen(true),
    },
    {
      title: 'Delete step',
      icon: <TrashIcon />,
      type: 'danger' as const,
      onSelect: () => setIsDeleteStepModalOpen(true),
    },
  ];

  const handleDeleteStep = async () => {
    await step.delete();
    const href = `../..`;
    navigate(href);
  };

  return (
    <React.Fragment>
      <ScreenHeader
        stepId={step._id}
        title={step.name}
        dueDate={step.dueDate}
        startDate={step.startDate}
        handleUpdateTimeFrame={(values) => step.update(values)}
        menu={<DropdownMenu items={items} size={'large'} />}
        actions={[
          <IconButton
            key="add"
            size={'medium'}
            onClick={() => setIsCreateModalOpen(true)}
            icon={<Plus />}
          />,
        ]}
      />

      <StepDrawer step={step} />

      <ScreenContainer>
        {hasCharacters ? (
          <CharacterCards characters={project.characters} />
        ) : (
          <CharacterListPlaceholder onAddCharacterClicked={() => setIsCreateModalOpen(true)} />
        )}

        {isCreateModalOpen && (
          <CreateCharacterDrawer
            projectId={project._id}
            onCancel={() => setIsCreateModalOpen(false)}
          />
        )}

        {uiStore.isTaskPanelOpen && !!uiStore.taskPanelTargetId && (
          <TaskPanel targetId={uiStore.taskPanelTargetId} />
        )}
      </ScreenContainer>

      {isRenameModalOpen && (
        <RenameStepModal step={step} onCancel={() => setIsRenameModalOpen(false)} />
      )}

      {isDeleteStepModalOpen && (
        <DeleteDialog
          title="Delete step"
          text={`Are you sure you want to delete this step?${
            step.tasks?.length ? ' All linked tasks will be gone as well.' : ''
          }`}
          onCancel={() => setIsDeleteStepModalOpen(false)}
          onSubmit={handleDeleteStep}
        />
      )}
    </React.Fragment>
  );
});

const LoaderContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: center;
  height: 61vh;
`;
