import React from 'react';

import { SelectDropdown } from './select-dropdown';

import type { SingleSelectDropdownProps } from '.';

export const SingleSelectDropdown: React.FC<SingleSelectDropdownProps> = ({
  title,
  options,
  groups,
  value,
  onValueChange,
  onCreate,
  trigger,
  canOpen,
  showCheckbox,
  placeholder,
  disabled,
}) => {
  const labels: { [key: string]: string } = {};

  const items: React.ComponentProps<typeof SelectDropdown>['items'] = options?.map((option) => {
    labels[option.value] = option.label;

    return {
      title: option.label,
      icon: option.icon,
      isChecked: option.value === value,
      onCheckedChange: (isChecked) => {
        isChecked ? onValueChange(option.value) : onValueChange();
      },
    };
  });

  const groupOptions: React.ComponentProps<typeof SelectDropdown>['groups'] = groups?.map(
    (group) => {
      const groupItems: React.ComponentProps<typeof SelectDropdown>['items'] = group.items.map(
        (item) => {
          labels[item.value] = item.label;

          return {
            ...item,
            title: item.label,
            isChecked: item.value === value,
            onCheckedChange: (isChecked) => {
              isChecked ? onValueChange(item.value) : onValueChange();
            },
          };
        },
      );

      return {
        title: group.title,
        items: groupItems,
      };
    },
  );

  const selectedItemLabel = value !== undefined ? labels[value] : undefined;

  const optionsProps = {
    ...(groupOptions ? { groups: groupOptions } : items && { items }),
  };

  return (
    <SelectDropdown
      title={title}
      placeholder={placeholder}
      selectedItemsLabel={selectedItemLabel}
      onCreate={onCreate}
      trigger={trigger}
      canOpen={canOpen}
      showCheckbox={showCheckbox}
      disabled={disabled}
      {...optionsProps}
    />
  );
};
