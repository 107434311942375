import React, { useEffect, useState } from 'react';
import { Outlet, Route, Routes, useNavigate, useParams } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import { ProjectRootScreen } from './pages/project-root.screen';
import { PageUnauthorized } from '../../components/unauthorized-placeholder/unauthorized-placeholder';
import { VideoComparePage } from './video-compare.page';
import { tabPool } from '../../features/tabs/models/tabs-pool';
import { uiStore } from '../../core/stores/ui-store';
import { CrashErrorScreen } from './components/crash-error-screen';
import { ProjectSettingsRoute } from './project-settings.route';
import { ProjectCalendarRoute } from './project-calendar.route';
import { useCurrentProject } from '../../hooks/use-current-project';
import { breakPoint } from 'app/theme';
import { ProjectMenu } from './components/project-menu';
import { bootstrapProject } from '../../features/projects/store/projects.slice';
import { PageLoader } from '../../app/components/page-loader';
import { Task, TaskStatus } from '../../app/entities/task';

export const ProjectPage = observer(() => {
  const { projectId } = useParams() as { projectId: string };
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const statuses = Object.values(TaskStatus);

  const [isDrawerOpen, setIsDrawerOpen] = useState(true);

  const project = useCurrentProject();

  /**
   * TODO URGENT
   *
   * How do we handle unauthorized errors ?
   */

  useEffect(() => {
    if (project) {
      setIsLoading(false);
    }

    bootstrapProject(projectId!)
      .then(() =>
        Task.fetchAll({
          projectId: projectId,
          status: statuses,
          role: ['created-by-me', 'assigned-to-me'],
        }),
      )
      .then(() => setIsLoading(false));
  }, [projectId, project, statuses]);

  useEffect(() => {
    if (project) {
      uiStore.pageTitle = {
        label: project.name,
        href: `/space/${project.spaceId}/projects/${project._id}`,
      };

      uiStore.showBack = true;
      uiStore.goBack = () => navigate('../');
    }

    tabPool.updateTab({
      key: projectId,
      label: project?.name,
    });

    return () => {
      uiStore.pageTitle = undefined;
      uiStore.showBack = false;
      uiStore.goBack = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [project, projectId]);

  return (
    <Sentry.ErrorBoundary fallback={<CrashErrorScreen />}>
      <Container>
        <ProjectMenu isOpen={isDrawerOpen} setIsOpen={setIsDrawerOpen} />

        <Content data-expand={isDrawerOpen}>
          {isLoading ? (
            <PageLoader />
          ) : (
            <>
              <Outlet />

              {project ? (
                <Routes>
                  <Route
                    path="/steps/:stepId/assets/:assetId/compare-versions"
                    element={<VideoComparePage />}
                  />
                  <Route path="/settings" element={<ProjectSettingsRoute project={project} />} />
                  <Route path="/calendar" element={<ProjectCalendarRoute project={project} />} />
                  <Route path="/" element={<ProjectRootScreen />} />
                </Routes>
              ) : (
                <PageUnauthorized />
              )}
            </>
          )}
        </Content>
      </Container>
    </Sentry.ErrorBoundary>
  );
});

const Container = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;

  & > div:first-child {
    display: none;
  }

  @media screen and (min-width: ${breakPoint.medium - 1}px) {
    & > div:first-child {
      display: flex;
    }
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: calc(100%);
  overflow: hidden;
  transition: all 0.2s ease-in;

  @media screen and (min-width: ${breakPoint.medium}px) {
    &[data-expand='true'] {
      max-width: calc(100% - 200px);
    }
  }
`;

export const Header = styled.div`
  height: 4rem;
  min-height: 4rem;
  border-bottom: 1px var(--content-border-color) solid;
  color: #e3e4e7;
  font-size: 1.4rem;
  font-weight: 600;
  padding: 0 1.6rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  svg {
    height: 1.6rem;
    width: 1.6rem;
  }
`;
