import React, { useState } from 'react';
import styled from 'styled-components';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

import { DownArrow } from '../icons/down-arrow';
import { BlueCheckmark } from '../icons/BlueCheckmark';
import { useOnClickOutside } from '../../hooks/useOutsideClick';
import {
  DescriptiveControl,
  DescriptiveOptionDescription,
  DescriptiveOptionLabel,
  StyledContainer,
  OptionsList,
} from './StyledDropdown';
import { Label, Help } from '../Input/styled-input';
import { DropdownProps } from '.';

type DescriptiveDropdownProps = DropdownProps & {
  helpText?: string;
  label?: string;
  required?: boolean;
};
/**
 * Mostly a copy of Inline Descriptive dropdown...
 * We can share most of the design but the main control item is really different
 * @constructor
 */
export const DescriptiveDropdown: React.FC<DescriptiveDropdownProps> = ({
  onChange,
  options,
  value,
  label,
  helpText,
  required,
}) => {
  const handleChange = (value: string) => {
    onChange(value);
  };

  const chosenElement = options.find((el) => el.value === value);

  return (
    <div>
      {label && (
        <Label>
          {label} {required && <b>*</b>}
        </Label>
      )}

      {helpText && <Help>{helpText}</Help>}

      <DropdownMenu.Root>
        <DropdownMenu.Trigger asChild>
          <DescriptiveControl>
            <ChosenOptionContainer>
              <ChosenOption>
                <DescriptiveOptionLabel>{chosenElement?.label}</DescriptiveOptionLabel>
                <DownArrow />
              </ChosenOption>

              <DescriptiveOptionDescription>
                {chosenElement?.description}
              </DescriptiveOptionDescription>
            </ChosenOptionContainer>
          </DescriptiveControl>
        </DropdownMenu.Trigger>

        <DropdownMenu.Portal>
          <Option sideOffset={4} align="start">
            {options!.map((el, index) => (
              <ListItem
                key={index}
                data-active={el.value === value}
                onSelect={() => handleChange(el.value)}
              >
                <DescriptiveOptionLabel>
                  {el.label} {el.value === value && <BlueCheckmark />}
                </DescriptiveOptionLabel>
                <DescriptiveOptionDescription>{el?.description} </DescriptiveOptionDescription>
              </ListItem>
            ))}
          </Option>
        </DropdownMenu.Portal>
      </DropdownMenu.Root>
    </div>
  );
};

const ChosenOption = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
`;

const ChosenOptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Option = styled(DropdownMenu.Content)`
  opacity: 2;
  min-width: 18rem;
  border-radius: 0.6rem;
  padding: 0.4rem;
  z-index: calc(var(--layer-modal) + 1);
  box-shadow: rgba(0, 0, 0, 0.2) 0 4px 24px;
  background: var(--color-grayscale-tuna);
  max-width: 46.8rem;

  &[data-size='free-size'] {
    min-width: unset;
  }
`;

const ListItem = styled(DropdownMenu.Item)`
  position: relative;
  border-radius: 0.4rem;
  display: flex;
  flex-direction: column;
  padding: 1.2rem;
  gap: 0.2rem;

  &[data-highlighted] {
    cursor: pointer;
    background-color: rgba(124, 124, 163, 0.125);
  }
`;
