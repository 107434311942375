import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ExclamationTriangleIcon } from '@radix-ui/react-icons';
import * as yup from 'yup';

import {
  BaseDialog,
  BaseDialogProps,
  DialogFooter,
  DialogHeader,
} from '../../../components/dialog';
import { ElevatedButton, TextButton } from '../../../components/buttons';
import { TextInput } from '../../../components/text-input/TextInput';
import { Label } from '../../../components/Input/styled-input';
import { SaveTaskDto, Task, TaskLevel, TaskStatus } from '../../../app/entities/task';
import { StatusSelector } from '../../process/components/status-selector';
import { Project, ProjectPriority } from '../../../app/entities/project';
import { Step } from '../../../app/entities/step';
import { PrioritySelector } from '../../process/components/priority-selector';
import { DateSelector } from '../../../components/date-selector/date-selector';
import { ProjectSelector } from './project-selector';
import { StepSelector } from './step-selector';
import { Tooltip } from '../../../components/tooltip/Tooltip';
import { TaskAssigneeSelector } from './task-assignee-selector';
import { breakPoint } from '../../../app/theme';
import { useCurrentSpace } from 'hooks/use-current-space';

type SaveTaskModalProps = BaseDialogProps & {
  defaultValues?: Partial<SaveTaskDto>;
  title: string;
  project?: Project;
  step?: Step;
  onClose: () => void;
};

export const SaveTaskModal: React.FC<SaveTaskModalProps> = ({
  onClose,
  title,
  defaultValues,
  project,
  step,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showDateWarning, setShowDateWarning] = useState(false);

  const level = step ? 'step' : project ? 'project' : 'space';

  const createSchema = yup.object().shape({
    title: yup.string().max(128, 'Max 128 characters').required(),
    status: yup.mixed<TaskStatus>().oneOf(Object.values(TaskStatus), 'Invalid status').required(),
    description: yup.string().max(1000, 'Max 1000 characters').optional(),
    priority: yup
      .mixed<ProjectPriority>()
      .oneOf(
        [
          ProjectPriority.NO_PRIORITY,
          ProjectPriority.LOW,
          ProjectPriority.MEDIUM,
          ProjectPriority.HIGH,
          ProjectPriority.URGENT,
        ],
        'Invalid priority',
      )
      .optional(),
    dueDate: yup
      .string()
      .transform((_, val) => {
        // if user clicked on the "clear" button
        if (val === '') return;
        return val;
      })
      .optional(),
    stepId: yup
      .string()
      .test({
        name: 'validateStepId',
        exclusive: false,
        message: 'step is required field',
        test: function (value, context) {
          const projectId = context.parent.projectId;
          return value ? !!projectId : level !== 'step';
        },
      })
      .optional(),
    assigneeId: yup.string().optional(),
    projectId: yup
      .string()
      .test({
        name: 'validateProjectId',
        exclusive: false,
        message: 'project is required field',
        test: function (value) {
          return level !== 'space' ? !!value : true;
        },
      })
      .optional(),
  });

  const {
    register,
    handleSubmit,
    getValues,
    control,
    watch,
    setValue,
    formState: { errors },
  } = useForm<SaveTaskDto>({
    mode: 'onSubmit',
    resolver: yupResolver(createSchema),
    defaultValues: {
      title: '',
      description: '',
      status: TaskStatus.Todo,
      priority: ProjectPriority.NO_PRIORITY,
      dueDate: undefined,
      projectId: project?._id,
      stepId: step?._id,
      assigneeId: undefined,
      ...defaultValues,
    },
  });

  const [watchedStepId, watchedDueDate] = watch(['stepId', 'dueDate']);

  useEffect(() => {
    if (!watchedStepId || !watchedDueDate) {
      setShowDateWarning(false);
      return;
    }

    const step = Step.getOne(watchedStepId);
    if (!step || !step.dueDate) {
      setShowDateWarning(false);
      return;
    }

    setShowDateWarning(new Date(watchedDueDate) > new Date(step.dueDate));
  }, [watchedStepId, watchedDueDate]);

  const onSubmit = async (input: SaveTaskDto) => {
    if (isLoading) {
      return;
    }

    setIsLoading(true);

    for (const key in input) {
      // @ts-ignore
      if (input[key] === '') delete input[key];
    }

    await Task.create(input);

    onClose();
    setIsLoading(false);
  };

  return (
    <BaseDialog onClose={onClose}>
      <DialogHeader title={title} onClose={onClose} />

      <Content>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <TextInput
              label="Task title"
              placeholder="eg. Write a compelling pitch for an indie film project"
              required
              {...register('title')}
              errorMessage={errors.title?.message}
            />
          </div>

          <Properties>Properties</Properties>

          <InputWrapper>
            <div>Status</div>

            <Controller
              name="status"
              control={control}
              render={({ field: { onChange, value } }) => (
                <StatusSelector
                  onChange={onChange}
                  statusMap={Task.StatusMapper}
                  status={value}
                  withTitle
                  isViewOnly={false}
                />
              )}
            />
          </InputWrapper>

          <InputWrapper>
            <div>Priority</div>

            <Controller
              name="priority"
              control={control}
              render={({ field: { onChange, value } }) => (
                <PrioritySelectorWrapper
                  data-priorityChanged={value !== ProjectPriority.NO_PRIORITY}
                >
                  <PrioritySelector onChange={onChange} priority={value} withTitle />
                </PrioritySelectorWrapper>
              )}
            />
          </InputWrapper>

          <InputWrapper>
            <div>Assignee</div>

            <Controller
              name="assigneeId"
              control={control}
              render={({ field: { onChange, value } }) => (
                <AssigneeWrapper>
                  <TaskAssigneeSelector selectedMemberId={value} onChange={onChange} />
                </AssigneeWrapper>
              )}
            />
          </InputWrapper>

          <InputWrapper>
            <div>Due date</div>

            <Controller
              name="dueDate"
              control={control}
              render={({ field: { onChange, value } }) => (
                <DateInput>
                  <DateSelectorWrapper data-dueDateChanged={!!value}>
                    <DateSelector
                      placeholder="Set due date"
                      field="dueDate"
                      date={value}
                      onUpdate={onChange}
                    />
                  </DateSelectorWrapper>

                  {showDateWarning && (
                    <DateWarning>
                      <ExclamationTriangleIcon />
                      <span>Date is later than the step&apos;s due date</span>
                    </DateWarning>
                  )}
                </DateInput>
              )}
            />
          </InputWrapper>

          <InputWrapper>
            <div>Project</div>

            <Controller
              name="projectId"
              control={control}
              render={({ field: { onChange, value } }) => (
                <ProjectOrStepSelectorWrapper data-inputChanged={!!value}>
                  <ProjectSelector
                    onChange={onChange}
                    selectedProjectId={value}
                    isViewOnly={level !== 'space'}
                  />
                </ProjectOrStepSelectorWrapper>
              )}
            />
          </InputWrapper>

          <InputWrapper>
            <div>Step</div>

            <Controller
              name="stepId"
              control={control}
              render={({ field: { onChange, value } }) => {
                const selectedProject = Project.getOne(getValues('projectId')!);
                return (
                  <Tooltip
                    disabled={!!selectedProject}
                    text="Select project first"
                    position="bottom"
                  >
                    <ProjectOrStepSelectorWrapper data-inputChanged={!!value}>
                      <StepSelector
                        project={selectedProject}
                        onChange={onChange}
                        selectedStepId={value}
                        isViewOnly={level == 'step'}
                        withIcon
                      />
                    </ProjectOrStepSelectorWrapper>
                  </Tooltip>
                );
              }}
            />
          </InputWrapper>
        </form>
      </Content>

      <DialogFooter
        actions={[
          <TextButton key={'cancel'} onClick={onClose} text={'Cancel'} />,
          <ElevatedButton
            key="save"
            text={'Create task'}
            onClick={handleSubmit(onSubmit)}
            isLoading={isLoading}
          />,
        ]}
      />
    </BaseDialog>
  );
};

const Content = styled.div`
  max-width: 47.5rem;
  padding: 1.6rem;
  flex: 1;
  overflow-y: scroll;

  ${Label} {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  @media screen and (min-width: ${breakPoint.small}px) {
    width: 47.5rem;
    max-width: 100%;
  }
`;

const Properties = styled.h2`
  margin: 2.4rem 0 1.6rem 0;
  font-family: Inter, sans-serif;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.8rem;
  color: var(--color-grayscale-white);
`;

const InputWrapper = styled.div`
  display: flex;
  gap: 1.6rem;
  align-items: center;
  margin-bottom: 1.6rem;

  & > div:first-child {
    width: 6.4rem;
    color: var(--color-grayscale-ghost);
    font-weight: 400;
    font-size: 1.4rem;
  }
`;

const DashedInput = css`
  background-color: transparent;
  border-radius: var(--border-radius-small);
  border: 1px var(--color-grayscale-light-slate) dashed;
  color: var(--color-grayscale-light-slate) !important;
  font-size: 1.4rem;
  font-weight: 400;
`;

const PrioritySelectorWrapper = styled.div`
  ${DashedInput}

  &:hover {
    border: 1px var(--color-grayscale-trout) solid;
    background-color: var(--color-grayscale-trout);
  }

  & > div {
    padding: 0.6rem 0.8rem;

    &:hover {
      background-color: var(--color-grayscale-trout);
    }

    & > div:last-child {
      color: var(--color-grayscale-light-slate);
    }
  }

  &[data-priorityChanged='true'] {
    border: none;
    padding-left: 0;
    padding-right: 0;

    & > div div {
      color: var(--color-grayscale-white);
    }
  }
`;

const AssigneeWrapper = styled.div`
  & > div {
    &:hover {
      border: 1px var(--color-grayscale-trout) solid;
      background-color: var(--color-grayscale-trout);
    }
  }
`;

const ProjectOrStepSelectorWrapper = styled.div`
  & > div {
    height: 100%;
    min-width: 100%;
    max-width: 100%;

    & > div {
      ${DashedInput}

      padding: 0.8rem;

      &:hover {
        background-color: var(--color-grayscale-trout);
        border: 1px var(--color-grayscale-trout) solid;

        > span {
          color: var(--color-grayscale-light-slate);
        }

        > svg {
          color: var(--color-grayscale-light-slate);
        }
      }
    }
  }

  &[data-inputChanged='true'] {
    & > div > div {
      border: none;
      padding-left: 0;
      padding-right: 0;
      background-color: transparent;

      > span {
        color: var(--color-grayscale-white);
      }

      &:hover {
        background-color: transparent;

        > span {
          color: var(--color-grayscale-white);
        }
      }
    }
  }
`;

const DateSelectorWrapper = styled.div`
  ${DashedInput}

  width: fit-content !important;

  &:hover {
    border: 1px var(--color-grayscale-trout) solid;
    background-color: var(--color-grayscale-trout);
  }

  & > div {
    padding: 0.8rem;
    font-size: 1.4rem;
    color: var(--color-grayscale-light-slate);

    &:hover {
      background-color: var(--color-grayscale-trout);
    }
  }

  &[data-dueDateChanged='true'] {
    border: none;
    padding-left: 0;
    padding-right: 0;
    color: var(--color-grayscale-white);
  }
`;

const DateWarning = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
  color: var(--color-grayscale-ghost);
  font-size: 1.2rem;
  font-weight: 400;
  margin-left: 0.8rem;
  margin-top: 0.4rem;

  & > svg {
    color: var(--color-secondary-saffron);
  }
`;

const DateInput = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    width: 100%;
  }
`;
