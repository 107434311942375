import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { CheckIcon } from '@radix-ui/react-icons';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';

import { KeyboardKeys } from '../../../assets/enums/keyboard-keys.enum';

import type { SelectDropdownProps } from '..';

type GroupContentProps = {
  items: SelectDropdownProps['groups'];
  showCheckbox?: boolean;
  placeholder?: string;
};

export const GroupContentMenu: React.FC<GroupContentProps> = ({
  items,
  showCheckbox = true,
  placeholder,
}) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [options, setOptions] = useState<typeof items>();

  useEffect(() => {
    if (!searchValue) {
      return setOptions(items);
    }

    if (items) {
      const filteredOptions = items
        .map((group) => {
          const filteredItems = group.items.filter((item) =>
            item.title?.toString().toLowerCase().includes(searchValue.toLowerCase()),
          );

          if (filteredItems.length > 0) {
            return { ...group, items: filteredItems };
          }

          return null;
        })
        .filter((group) => group !== null);

      setOptions(filteredOptions);
    }
  }, [items, searchValue]);

  return (
    <MenuContainer sideOffset={4} align="start">
      <SearchInput
        placeholder={placeholder || 'Search'}
        tabIndex={0}
        value={searchValue}
        onKeyDown={(e) => {
          if (e.key !== KeyboardKeys.Escape) {
            e.stopPropagation();
          }
        }}
        onChange={(e) => {
          const value = e.currentTarget.value;
          setSearchValue(value);
        }}
        autoFocus
      />

      <OptionsContainer>
        {items?.length !== 0 &&
          (options as typeof items)?.map((group, index) => (
            <GroupContainer key={`${group.title}-${index}`}>
              {group.title && group.items?.length && <GroupTitle>{group.title}</GroupTitle>}

              <DropdownMenu.Group>
                {group.items.map((item, itemIndex) => (
                  <CheckboxItem
                    key={`item-${item.title}-${itemIndex}`}
                    checked={item.isChecked}
                    onCheckedChange={(isChecked) => {
                      item.onCheckedChange?.(isChecked);
                    }}
                  >
                    {item.icon}

                    <span>{item.title}</span>

                    <div style={{ flex: 1 }} />
                    {showCheckbox && (
                      <IconContainer>{!!item.isChecked && <CheckIcon />}</IconContainer>
                    )}
                  </CheckboxItem>
                ))}
              </DropdownMenu.Group>
            </GroupContainer>
          ))}
      </OptionsContainer>
    </MenuContainer>
  );
};

const MenuContainer = styled(DropdownMenu.Content)`
  display: flex;
  flex-direction: column;
  padding: 0.4rem 0;
  width: 100%;
  overflow-y: auto;
  min-width: 32rem;
  max-height: 32rem;
  border-radius: 0.4rem;
  box-shadow: 0px 4px 8px -3px #0000001a;
  backdrop-filter: blur(10px) saturate(190%) contrast(70%) brightness(80%);
  background-color: var(--color-surfaces-bg-elevation-2);
  transition: all 100ms ease-in-out;
  z-index: 1000000;
`;

const SearchInput = styled.input`
  background-color: transparent;
  color: var(--color-grayscale-white);
  border: none;
  border-bottom: 0.1rem solid var(--color-surfaces-bg-elevation-3);
  padding: 0.8rem;
  width: 100%;
  height: 3.2rem;
  font-weight: 400;
  font-size: 1.2rem;

  &::placeholder {
    color: var(--color-texts-low-contrast);
  }
`;

const OptionsContainer = styled.div`
  padding: 0.4rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
`;

const Separator = styled(DropdownMenu.Separator)`
  width: 100%;
  margin: 0.8rem 0 0.4rem;
  border-bottom: 0.1rem solid var(--color-grayscale-tuna);
`;

const BaseMenuItemStyles = css`
  color: var(--color-texts-high-contrast);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 0.2rem;
  padding: 0.8rem;
  font-size: 1.2rem;
  font-weight: 400;
  height: 3.2rem;

  > svg {
    margin-right: 0.8rem;
  }

  &[data-highlighted] {
    cursor: pointer;
    background-color: var(--color-surfaces-bg-elevation-3);
  }

  &[data-disabled] {
    color: var(--color-grey-7);
    pointer-events: none;
  }

  &[data-type='danger'] {
    color: var(--color-error);
  }
`;

const CheckboxItem = styled(DropdownMenu.CheckboxItem)`
  ${BaseMenuItemStyles}
  gap: 0.8rem;
`;

const IconContainer = styled.div`
  height: 1.8rem;
  width: 1.8rem;
`;

const GroupContainer = styled.div``;

const GroupTitle = styled.div`
  font-size: 1.2rem;
  line-height: 1.2rem;
  font-weight: 400;
  padding: 1.2rem 0.8rem 0.8rem;
  color: var(--color-texts-low-contrast);
`;
