import React, { useEffect, useState } from 'react';
import { ClipboardIcon } from '@radix-ui/react-icons';
import { observer } from 'mobx-react-lite';

import { uiStore } from '../../core/stores/ui-store';

import { TaskList } from '../../features/tasks/components/tasks-list';
import { ElevatedButton } from '../../components/buttons';
import { SaveTaskModal } from '../../features/tasks/components/save-task.modal';
import { PagePlaceholder } from '../../components/page-placeholder/page-placeholder';
import { Header } from '../project/project.page';
import { useCurrentSpace } from 'hooks/use-current-space';
import { Task, TaskStatus } from '../../app/entities/task';
import styled from 'styled-components';
import { PageLoader } from '../../app/components/page-loader';
import { fetchCalendarData } from 'features/spaces/stores/space.slice';

const statuses = Object.values(TaskStatus);

export const SpaceTasksRoute: React.FC = observer(() => {
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const space = useCurrentSpace();

  useEffect(() => {
    uiStore.currentLocation = 'space.tasks';

    return () => {
      uiStore.currentLocation = '';
    };
  }, []);

  const spaceProjects = space?.projects;

  useEffect(() => {
    const fetchData = async () => {
      try {
        await Promise.all([
          fetchCalendarData(space._id),
          Task.fetchAll({
            status: statuses,
            role: ['created-by-me', 'assigned-to-me'],
          }),
        ]);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [spaceProjects]);

  const Content = () => {
    if (isLoading) {
      return <PageLoader />;
    } else if (space.tasks.length === 0) {
      return (
        <PagePlaceholder
          title="You're off to a clean start!"
          description="Start by creating a task to keep things organized and on track."
          fullWidth
          backgroundImgUrl="/images/order-from-chaos.svg"
        />
      );
    } else {
      return <TaskList tasks={space.tasks} taskLevel="space" />;
    }
  };

  return (
    <Container>
      <Header>
        <span>Tasks</span>

        <ElevatedButton
          icon={<ClipboardIcon style={{ stroke: 'unset' }} />}
          text="Create task"
          onClick={() => setIsCreateModalOpen(true)}
        />
      </Header>

      <Content />

      {isCreateModalOpen && (
        <SaveTaskModal onClose={() => setIsCreateModalOpen(false)} title="Create task" />
      )}
    </Container>
  );
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
