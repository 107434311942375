import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';

import { ScreenContainer } from '../../projects/components/styled-project-components';
import { CallsheetTab } from '../components/callsheet-tab';
import { ShootingDayHeader } from '../components/shooting-day-screen.header';
import { StepDrawer } from '../../projects/components/step.drawer';
import { uiStore } from '../../../core/stores/ui-store';
import { TaskPanel } from '../../tasks/components/task.panel';

import type { Project } from '../../../app/entities/project';
import type { Step } from '../../../app/entities/step';

interface ShootingDayScreenProps {
  project: Project;
  step: Step;
}

export const ShootingDayScreen: React.FC<ShootingDayScreenProps> = observer(({ project, step }) => {
  useEffect(() => {
    return () => uiStore.closeTaskPanel();
  }, []);

  return (
    <React.Fragment>
      <ShootingDayHeader step={step} project={project} />

      <StepDrawer step={step} />

      <ScreenContainer>
        <CallsheetTab project={project} step={step} />

        {uiStore.isTaskPanelOpen && !!uiStore.taskPanelTargetId && (
          <TaskPanel targetId={uiStore.taskPanelTargetId} />
        )}
      </ScreenContainer>
    </React.Fragment>
  );
});
