import React, { useState } from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { Cross1Icon, ClipboardIcon } from '@radix-ui/react-icons';

import { Model } from '../../../core/engine/model';
import { ToolBar, Tools } from '../../../components/toolbar/toolbar';
import { ElevatedButton, IconButton } from '../../../components/buttons';
import { TaskList } from './tasks-list';
import { uiStore } from '../../../core/stores/ui-store';
import { SaveTaskModal } from './save-task.modal';

import type { Step } from '../../../app/entities/step';
import type { Project } from '../../../app/entities/project';
import type { Space } from '../../../app/entities/space';
import type { TaskLevel } from '../../../app/entities/task';
import { PagePlaceholder } from '../../../components/page-placeholder/page-placeholder';

interface TaskPanelProps {
  targetId: string;
}

export const TaskPanel: React.FC<TaskPanelProps> = observer(
  ({ targetId }: { targetId: string }) => {
    const [openModal, setOpenModal] = useState(false);
    const target = Model.getOne(targetId) as Space | Project | Step;

    const taskLevel: TaskLevel = !!(target as Space).ownerId
      ? 'space'
      : !!(target as Step).projectId
      ? 'step'
      : 'project';

    const getProject = (): Project | undefined => {
      if (taskLevel === 'step') return (target as Step).project!;
      if (taskLevel === 'project') return target as Project;
      // undefined in case of space level
    };

    const getStep = (): Step | undefined => {
      if (taskLevel === 'step') return target as Step;
      // undefined in case of space or project levels
    };

    return (
      <Container>
        <ToolBar title={target.name}>
          <Tools>
            <ElevatedButton
              variant="primary"
              icon={<ClipboardIcon />}
              text="Create task"
              onClick={() => setOpenModal(true)}
            />

            <IconButton
              size={'small'}
              icon={<Cross1Icon />}
              onClick={() => uiStore.closeTaskPanel()}
            />
          </Tools>
        </ToolBar>

        {target.tasks.length === 0 ? (
          <PagePlaceholder
            description="No tasks in this step."
            backgroundImgUrl="/images/order-from-chaos.svg"
          />
        ) : (
          <TaskList tasks={target.tasks || []} taskLevel={taskLevel} />
        )}

        {openModal && (
          <SaveTaskModal
            onClose={() => setOpenModal(false)}
            title="Create task"
            project={getProject()}
            step={getStep()}
          />
        )}
      </Container>
    );
  },
);

const Container = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 50%;
  border-left: 1px var(--content-border-color) solid;
  display: flex;
  flex-direction: column;
  background-color: var(--app-background);
  z-index: 1000;
  box-shadow: 0 0.4rem 0.4rem rgba(0, 0, 0, 0.25);
`;
