import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

interface ToolBarProps {
  title?: string;
}

export const ToolBar: React.FC<PropsWithChildren<ToolBarProps>> = ({ title, children }) => (
  <Container>
    {title && <Title>{title}</Title>}
    {children}
  </Container>
);

export const Container = styled.div`
  height: 4rem;
  min-height: 4rem;
  border-bottom: 1px var(--content-border-color) solid;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.6rem;
`;

const Title = styled.h3`
  font-size: 1.4rem;
  color: var(--text-color-white);
`;

export const Tools = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.8rem;
  color: var(--color-texts-high-contrast);

  & button:not(div#download-share-button button) {
    height: 3.2rem;
    min-width: 3.2rem;
    width: auto;
    background-color: var(--color-surfaces-bg-elevation-1);
    color: var(--color-texts-high-contrast);

    & svg {
      width: 1.6rem;
      height: 1.6rem;
      stroke: unset;
      fill: unset;
      color: var(--color-texts-high-contrast);
    }

    &:hover:enabled,
    &:focus:enabled,
    &:active:enabled {
      background-color: var(--color-surfaces-bg-elevation-2);

      & > svg {
        stroke: unset;
        fill: unset;
      }
    }
  }
`;
