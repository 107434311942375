import React from 'react';
import styled from 'styled-components';
import prettyBytes from 'pretty-bytes';
import { observer } from 'mobx-react-lite';

import { Progress } from '../../../components/progress/Progress';
import { getStorageRegionDisplayName } from '../services/storage.service';

import { Space } from '../../../app/entities/space';

type StorageProgressProps = {
  space: Space;
};

export const StorageProgress: React.FC<StorageProgressProps> = observer(({ space }) => {
  const percentage = (space.storage.current / space.storage.limit) * 100;
  const region = getStorageRegionDisplayName(space.storage.region);
  const total = prettyBytes(space.storage.current || 0);
  const limit = prettyBytes(space.storage.limit);

  return (
    <PanelContainer>
      <PanelHeader>
        <HeaderText>Storage used</HeaderText>
        <HeaderText>Storage size</HeaderText>
      </PanelHeader>

      <AmountSpaceContainer>
        <Amount>{total}</Amount>
        <Amount disable>{limit}</Amount>
      </AmountSpaceContainer>

      <Progress percentage={percentage} />

      <PanelFooter>
        <UsedAmount>{percentage.toFixed(2)}%</UsedAmount>
        <LocationContainer>
          <span>Storage region</span> • <span>{region}</span>
        </LocationContainer>
      </PanelFooter>
    </PanelContainer>
  );
});

const PanelContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const PanelHeader = styled.header`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.3rem;
`;

const HeaderText = styled.h2`
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: #afb4c0;
`;

const AmountSpaceContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

type AmountProps = {
  disable?: boolean;
};

const Amount = styled.p<AmountProps>`
  font-style: normal;
  font-weight: 500;
  font-size: 2rem;
  line-height: 2.4rem;
  color: ${({ disable }) => (disable ? '#626878' : '#ffffff')};
`;

const PanelFooter = styled.footer`
  display: flex;
  justify-content: space-between;
  margin-top: 1.2rem;
`;

const UsedAmount = styled.label`
  color: #367bff;
`;

const LocationContainer = styled.label`
  & > span:first-of-type {
    color: #959bab;
  }

  & > span:last-of-type {
    color: #ffffff;
  }
`;
