import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useIntersectionObserver } from 'usehooks-ts';
import { BellIcon, Cross1Icon } from '@radix-ui/react-icons';
import styled from 'styled-components';

import { Drawer } from '../../components/drawer/drawer';
import { breakPoint } from '../theme';
import { novuService } from '../../core/services/novu.service';
import { NotificationItem } from './notification-item';
import { authStore } from '../../core/stores/auth-store';
import { PagePlaceholder } from '../../components/page-placeholder/page-placeholder';

export const NotificationCenter: React.FC = observer(() => {
  const currentUser = authStore.currentUser;

  const [isCenterOpen, setIsCenterOpen] = useState(false);

  const notificationListRef = useRef<HTMLDivElement>(null);

  const { isIntersecting, ref: lastItemRef } = useIntersectionObserver({
    root: notificationListRef.current,
    threshold: 0.1,
  });

  useEffect(() => {
    if (currentUser?._id) {
      novuService.initializeSession(currentUser._id);
    }
  }, [currentUser?._id]);

  useEffect(() => {
    if (isIntersecting && novuService.hasMore) {
      novuService.fetchNotifications(novuService.totalCount / 5, 5);
    }
  }, [isIntersecting]);

  const handleMarkAllAsRead = () => {
    const unReadNotificationIds = novuService.notifications
      .filter((notification) => !notification.read)
      .map(({ _id }) => _id);

    if (unReadNotificationIds) {
      novuService.markNotificationsAsRead(unReadNotificationIds);
    }
  };

  return (
    <>
      <BellButton
        data-testid="notification-icon"
        data-count={novuService.unreadCount}
        onClick={() => setIsCenterOpen(true)}
      >
        <BellIcon />
      </BellButton>

      <NotificationDrawer
        isOpen={isCenterOpen}
        onCancel={() => setIsCenterOpen(false)}
        mobileFullHeight
      >
        <Header>
          <Title>Notifications</Title>

          <Actions>
            <MarkReadButton onClick={handleMarkAllAsRead} data-testid="mark-as-read">
              Mark all as read
            </MarkReadButton>

            {/* <IconButtonWrapper>
              <IconButton icon={<MixerHorizontalIcon />} onClick={() => setIsCenterOpen(false)} />
            </IconButtonWrapper> */}

            {/* <IconButtonWrapper>
              <IconButton icon={<GearIcon />} onClick={() => setIsCenterOpen(false)} />
            </IconButtonWrapper> */}

            <CloseButton onClick={() => setIsCenterOpen(false)} aria-label="Close">
              <Cross1Icon />
            </CloseButton>
          </Actions>
        </Header>

        <List ref={notificationListRef}>
          <MarkReadButton onClick={handleMarkAllAsRead} data-testid="mark-as-read">
            Mark all as read
          </MarkReadButton>

          {novuService.notifications?.length ? (
            novuService.notifications.map((notification, index) => (
              <NotificationItem
                ref={index === novuService.totalCount - 1 ? lastItemRef : null}
                key={notification._id}
                notification={notification}
                onClick={() => setIsCenterOpen(false)}
              />
            ))
          ) : (
            <EmptyPlaceholderWrapper>
              <PagePlaceholder
                backgroundImgUrl={'/images/default-background.svg'}
                description="There are no messages to show."
              />
            </EmptyPlaceholderWrapper>
          )}
        </List>
      </NotificationDrawer>
    </>
  );
});

const BellButton = styled.button`
  all: unset;
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: ${breakPoint.medium - 1}px) {
    padding: 0.8rem;
  }

  svg {
    color: var(--color-grayscale-manatee);

    width: 1.6em;
    height: 1.6rem;
  }

  &:not([data-count='0']) {
    &:after {
      width: 1.6rem;
      height: 1.6rem;
      content: attr(data-count);
      position: absolute;
      top: 0.6rem;
      right: 0.5rem;
      background: var(--color-primary-telemagenta, #d53075);
      border-radius: 50%;
      color: var(--color-grayscale-white, #fafafa);
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      font-size: 1rem;
      font-weight: 700;

      @media screen and (min-width: ${breakPoint.medium}px) {
        top: -0.2rem;
        right: -0.3rem;
      }
    }
  }

  @media screen and (min-width: ${breakPoint.medium}px) {
    min-width: 2.4rem;

    svg {
      width: 2rem;
      height: 2rem;
    }
  }
`;

const NotificationDrawer = styled(Drawer)`
  padding: 2.4rem 0 0;

  @media screen and (min-width: ${breakPoint.medium}px) {
    width: 48rem;
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 1.6rem 1.6rem;
  border-bottom: 0.1rem solid var(--color-grayscale-tuna, #31343c);
`;

const Title = styled.h1`
  font-size: 1.6rem;
  color: var(--color-grayscale-light-slate, #7a8296);
`;

const MarkReadButton = styled.button`
  all: unset;
  color: var(--color-primary-crayola, #367bff);
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2.4rem;
  cursor: pointer;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.6rem;

  @media screen and (max-width: ${breakPoint.medium - 1}px) {
    & > ${MarkReadButton} {
      display: none;
    }
  }
`;

const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  padding: 1.6rem 2.4rem 2.4rem;
  overflow-y: auto;
  height: 100%;

  & > ${MarkReadButton} {
    display: none;
  }

  @media screen and (max-width: ${breakPoint.medium - 1}px) {
    padding: 1.6rem;

    & > ${MarkReadButton} {
      display: block;
      width: fit-content;
      align-self: end;
      margin-bottom: 0.8rem;
    }
  }
`;

const EmptyPlaceholderWrapper = styled.div`
  width: 100%;
  height: 100%;

  div {
    user-select: none;
    background-color: transparent;
    background-size: 24rem;
    max-width: unset;

    p {
      display: inline-block;
      font-weight: 500;
      font-size: 2.4rem;
      color: var(--color-grayscale-cadet, #afb4c0);
    }
  }
`;

const CloseButton = styled.button`
  all: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3.2rem;
  height: 3.2rem;
  border-radius: 0.4rem;
  color: var(--color-grayscale-shuttle);
  box-sizing: border-box;
  border: 0.15rem solid transparent;
  cursor: pointer;

  @media screen and (min-width: ${breakPoint.medium}px) {
    display: none;
  }
`;
