import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  MouseSensor,
  pointerWithin,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { snapCenterToCursor } from '@dnd-kit/modifiers';
import { FilePlusIcon, FileTextIcon } from '@radix-ui/react-icons';
import styled from 'styled-components';

import { FileList } from '../../../components/file-list/file-list';
import { ConfigureLinkTypeAssetModal } from '../../process/components/configure-link-type-asset.modal';
import { FolderPlus, GridIcon, LinkPlus, ListDisplayIcon } from '../../../components/icons';
import { StyledButton } from '../../../components/buttons/styled-buttons';
import { IconButton } from '../../../components/buttons';
import { Tooltip } from '../../../components/tooltip/Tooltip';
import { SelectionProvider, useSelection } from '../../../core/contexts/selection.context';
import { CountDragPreview } from '../../schedule/components/count-drag-preview';
import { SORTING_TYPES } from '../../../assets/enums/assetType.enum';
import { createAsset } from '../store/assets.slice';
import { AddAssetButton } from './add-asset-button';
import { Asset } from '../../../app/entities/asset';
import { Folder } from '../../../app/entities/folder';
import { UploadButton } from './upload-button';
import { ToolBar, Tools } from 'components/toolbar/toolbar';
import { DownloadShareButton } from './download-share-button';
import { FilterButton } from './filter-button';
import { useResponsive } from '../../../hooks/useResponsive';
import { useCurrentMember } from '../../../hooks/use-current-member';
import { breakPoint } from 'app/theme';

interface FolderViewProps {
  folderId: string;

  stepId?: string;

  /**
   * Set to true if displaying a root folder, only impact the navigation.
   */
  isRoot?: boolean;
}

export const FolderView = observer(({ folderId, stepId, isRoot = false }: FolderViewProps) => {
  const folder = Asset.getOne(folderId);

  if (!folder) return null;

  return (
    <SelectionProvider items={folder.children.map((item) => item._id)}>
      <View folderId={folderId} isRoot={isRoot} stepId={stepId} />
    </SelectionProvider>
  );
});

const View: React.FC<FolderViewProps> = observer(({ folderId, stepId, isRoot = false }) => {
  const { isDesktop } = useResponsive();

  const folder = Asset.getOne(folderId)!;
  const navigate = useNavigate();
  const [isCreateLinkModalOpen, setIsCreateLinkModalOpen] = useState(false);
  const [activeDraggedAsset, setActiveDraggedAsset] = useState<string | null>(null);

  const { select, elements } = useSelection();

  const currentMember = useCurrentMember();
  const userStepPreferences = stepId
    ? currentMember?.preferences.stepsUI[stepId]
    : currentMember?.preferences.stepsUI[folderId];
  const sortBy = userStepPreferences?.order || SORTING_TYPES.UPLOADED_DATE;

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
  );

  const onDragStart = ({ active }: DragStartEvent) => {
    select(active.id.toString());
    setActiveDraggedAsset(active.id.toString());
  };

  const onDragEnd = ({ over }: DragEndEvent) => {
    if (!over) return;
    setActiveDraggedAsset(null);

    if (over.data.current?.type === 'folder') {
      const folder = Asset.getOne(over.id.toString());
      if (!folder) return;

      for (const element of elements) {
        const asset = Asset.getOne(element);
        if (!asset) continue;
        const currentParent = Asset.getOne(asset.parentId);
        if (!currentParent || asset._id === over.id || asset.parentId === over.id) continue;
        currentParent.children = currentParent.children.filter((child) => child._id !== asset._id);
        asset.update({ parentId: folder._id });
        asset.parent = folder;
        folder.children.push(asset);
      }
    }
  };

  const toggleDisplayLayout = () => {
    const newLayout = userStepPreferences?.displayLayout === 'list' ? 'grid' : 'list';
    if (stepId) {
      currentMember?.updateStepLayout(stepId, newLayout);
    } else {
      currentMember?.updateStepLayout(folderId, newLayout);
    }
  };

  const handleFileNavigation = async (asset: string, assetId: string) => {
    const href = isRoot ? `./${assetId}` : `../${assetId}`;
    navigate(href);
  };

  const handleAddFiles = async (files: File[]) => {
    for (const file of files) {
      await createAsset({
        parentId: folderId,
        file,
        name: file.name,
        size: file.size,
        type: 'file',
        fileType: file.type,
      });
    }
  };

  const handleCreateNoteAsset = async () => {
    const { asset } = await createAsset({
      parentId: folderId,
      type: 'note',
      name: 'Untitled Note',
      content: '',
    });
    // redirect to newly created note
    if (asset) navigate(isRoot ? `./${asset._id}` : `../${asset._id}`);
  };

  const handleCreateFolder = async () => {
    const newFolder = new Folder(folderId);

    let newFolderNumber = 0;

    const existingNumbers = new Set();

    const childrenFolders = folder.children.filter((child) => child.type === 'folder');

    for (const child of childrenFolders) {
      child.name = child.name.trim();

      if (child.name === 'New folder' && newFolderNumber === 0) {
        newFolderNumber = 1;
      }
      const match = child.name.match(/^New folder (\d+)$/);
      if (match) {
        existingNumbers.add(parseInt(match[1], 10));
      }
    }

    for (let i = newFolderNumber; i <= childrenFolders.length; i++) {
      if (!existingNumbers.has(i)) {
        newFolderNumber = i;
        break;
      }
    }

    newFolder.name = newFolderNumber === 0 ? 'New folder' : `New folder ${newFolderNumber}`;

    createAsset({
      type: 'folder',
      name: newFolder.name,
      parentId: folderId,
    });
  };

  const handleOnCreateLink = async (values: { address: string; name?: string }) => {
    await createAsset({
      parentId: folderId,
      type: 'link',
      url: values.address,
      name: values.name,
    });
  };

  const onSortBySelectList = (sortBy: string) => {
    if (stepId) {
      currentMember?.changeStepOrder(stepId, sortBy);
    } else {
      currentMember?.changeStepOrder(folderId, sortBy);
    }
  };

  if (!folder) {
    return;
  }

  return (
    <Container>
      <ToolBar>
        <Tools>
          <Tooltip text="Add files" position="bottom">
            {/* wrapping div is important for tooltip to work  */}
            <div>
              <UploadButton
                key="upload"
                onUploadFileValue={handleAddFiles}
                shouldAddFloatButton={false}
                icon={<FilePlusIcon color="var(--color-texts-high-contrast)" />}
                variant="icon"
                multiple
              />
            </div>
          </Tooltip>

          <Tooltip text="Add link" position="bottom">
            <IconButton icon={<LinkPlus />} onClick={() => setIsCreateLinkModalOpen(true)} />
          </Tooltip>

          <Tooltip text="Create folder" position="bottom">
            <IconButton icon={<FolderPlus />} onClick={handleCreateFolder} />
          </Tooltip>

          <Tooltip text="Add note" position="bottom">
            <IconButton
              icon={<FileTextIcon color="var(--color-texts-high-contrast)" />}
              onClick={handleCreateNoteAsset}
            />
          </Tooltip>
        </Tools>

        <Tools>
          {isDesktop && (
            <DownloadShareButton spaceId={folder.project!.spaceId} projectId={folder.projectId} />
          )}

          <FilterButton
            value={userStepPreferences?.order || SORTING_TYPES.UPLOADED_DATE}
            onSelect={onSortBySelectList}
          />

          <LayoutToggleButton key="change-layout-button" onClick={toggleDisplayLayout}>
            <span data-active={userStepPreferences?.displayLayout === 'grid'}>
              <GridIcon />
            </span>

            <span data-active={userStepPreferences?.displayLayout === 'list'}>
              <ListDisplayIcon />
            </span>
          </LayoutToggleButton>

          <AddAssetButton
            onSelectFile={handleAddFiles}
            onSelectLink={() => setIsCreateLinkModalOpen(true)}
            onSelectNote={handleCreateNoteAsset}
            onCreateFolder={handleCreateFolder}
          />
        </Tools>
      </ToolBar>

      <DndContext
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
        sensors={sensors}
        collisionDetection={pointerWithin}
      >
        <Content>
          <FileList
            files={folder.children}
            sortBy={sortBy}
            onAddFiles={handleAddFiles}
            assetsRouteTo={handleFileNavigation}
            displayLayout={isDesktop ? userStepPreferences?.displayLayout : 'grid'}
          />
        </Content>
        {createPortal(
          <DragOverlay modifiers={[snapCenterToCursor]}>
            {activeDraggedAsset && (
              <OverlayWrapper>
                <CountDragPreview
                  count={elements?.length}
                  text={`${elements.length} Asset${elements?.length > 1 ? 's' : ''}`}
                />
              </OverlayWrapper>
            )}
          </DragOverlay>,
          document.body,
        )}
      </DndContext>

      {isCreateLinkModalOpen && (
        <ConfigureLinkTypeAssetModal
          onSubmit={(values) => handleOnCreateLink(values as { address: string; name?: string })}
          onCancel={() => setIsCreateLinkModalOpen(false)}
        />
      )}
    </Container>
  );
});

const OverlayWrapper = styled.div`
  position: absolute;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 100%;
  height: 100%;
`;

const Content = styled.div`
  flex: 1;
  max-width: 100%;
  overflow-y: auto;
`;

const LayoutToggleButton = styled(StyledButton)`
  align-items: stretch;
  justify-content: space-between;
  height: 3.2rem;
  display: none;

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    height: 100%;
    width: 3.2rem;
    transition: background-color 0.3s ease;

    svg {
      width: 1.6rem;
      height: 1.6rem;
    }

    &[data-active='true'] {
      background-color: var(--color-primary-crayola);

      svg {
        path {
          fill: white;
        }
      }
    }
  }

  @media screen and (min-width: ${breakPoint.medium}px) {
    display: flex;
  }
`;
