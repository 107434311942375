import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import styled, { css } from 'styled-components';

import { DateSelector } from '../../../components/date-selector/date-selector';
import { differenceInCalendarDays } from 'date-fns';

import { TaskStatus, type Task } from '../../../app/entities/task';

type DateSelectorProps = {
  date?: string;
  status: Task['status'];
  onUpdate: (date: string) => void;
  isViewOnly?: boolean;
};

export const TaskDateSelector: React.FC<DateSelectorProps> = observer(
  ({ date, onUpdate, isViewOnly, status }) => {
    const [dateIconType, setDateIconType] = useState<'default' | 'danger' | 'warning'>('default');

    useEffect(() => {
      if (!date || [TaskStatus.Done, TaskStatus.Canceled].includes(status)) {
        return setDateIconType('default');
      }

      const today = new Date();
      const dueDate = new Date(date);

      const dateDifference = differenceInCalendarDays(dueDate, today);

      if (dateDifference <= 0) {
        setDateIconType('danger');
      } else if (dateDifference < 4) {
        setDateIconType('warning');
      } else {
        setDateIconType('default');
      }
    }, [date, status]);

    return (
      <DateSelectorWrapper data-set={!!date} data-type={dateIconType} data-disabled={isViewOnly}>
        <DateSelector
          placeholder="Set due date"
          field="dueDate"
          date={date}
          onUpdate={onUpdate}
          disabled={isViewOnly}
        />
      </DateSelectorWrapper>
    );
  },
);

const DashedInput = css`
  background: transparent;
  border-radius: var(--border-radius-small);
  border: 1px var(--color-grayscale-light-slate) dashed;
  color: var(--color-grayscale-light-slate);
  font-size: 1.2rem;
  font-weight: 400;
`;

const DateSelectorWrapper = styled.div`
  ${DashedInput}

  width: fit-content !important;

  &:hover {
    border: 1px var(--color-grayscale-trout) solid;
    background-color: var(--color-grayscale-trout);
  }

  &[data-disabled='true'] {
    pointer-events: none;
    user-select: none;
  }

  & > div {
    padding: 0.4rem 0.8rem;
    font-size: 1.2rem;
    font-weight: 400;
    color: var(--color-texts-low-contrast);

    &[data-dateSet='true'] {
      background: var(--color-surfaces-bg-elevation-2);
    }

    &:hover {
      background-color: var(--color-surfaces-bg-elevation-3);
      color: var(--color-texts-high-contrast);
    }
  }

  &[data-set='true'] {
    border: none;
    padding-left: 0;
    padding-right: 0;

    &[data-type='danger'] {
      svg {
        color: var(--color-texts-error);
      }
    }

    &[data-type='warning'] {
      svg {
        color: var(--color-secondary-saffron);
      }
    }

    color: var(--color-texts-high-contrast);

    svg {
      color: var(--color-texts-high-contrast);
    }
  }
`;
