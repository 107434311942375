import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import { SingleSelectDropdown } from '../../../components/select-dropdown';
import { MovieIcon } from '../../../components/icons/MovieIcon';
import { useCurrentSpace } from 'hooks/use-current-space';
import { TaskTrigger } from './styled-tasks-components';

type ProjectSelectorProps = {
  onChange: (value: string) => void;
  selectedProjectId: string | undefined;
  size?: 'default' | 'small';

  // Project is predefined if you try to create a task from a project page
  isViewOnly?: boolean;
};

export const ProjectSelector: React.FC<ProjectSelectorProps> = observer(
  ({ onChange, selectedProjectId, isViewOnly = false, size = 'default' }) => {
    const space = useCurrentSpace();

    const options: React.ComponentProps<typeof SingleSelectDropdown>['options'] =
      space?.projects?.map((project) => ({
        label: project.name,
        value: project._id,
      })) || [];

    const handleProjectChange = (value?: string) => {
      onChange(value || '');
    };

    return (
      <Container>
        <SingleSelectDropdown
          trigger={(label) => (
            <TaskTrigger
              data-disabled={isViewOnly}
              data-placeholder={!label}
              tabIndex={0}
              data-size={size}
            >
              {label ? (
                <ProjectLabel>
                  <MovieIcon fill={isViewOnly ? 'var(--color-grayscale-shuttle)' : undefined} />

                  <InfoTextLine tabIndex={0}>{label}</InfoTextLine>
                </ProjectLabel>
              ) : (
                <>
                  <MovieIcon />

                  <span>Select project</span>
                </>
              )}
            </TaskTrigger>
          )}
          title="Project"
          value={selectedProjectId}
          placeholder="Search"
          options={options}
          onValueChange={handleProjectChange}
          canOpen={!isViewOnly}
          showCheckbox={false}
        />
      </Container>
    );
  },
);

const Container = styled.div`
  width: fit-content;
  overflow: hidden;
`;

const Trigger = styled.div`
  display: flex;
  min-width: 0;
  height: 100%;
  padding: 0 0.8rem;
  height: 3.2rem;
  background: var(--color-surfaces-bg-elevation-2);
  align-items: center;
  border-radius: 0.4rem;
  border: 0.1rem solid transparent;
  gap: 0.8rem;

  & > span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &[data-placeholder='true'] {
    flex-direction: row;
    color: var(--color-texts-low-contrast);
    background: transparent;
    border: 0.1rem dashed var(--color-texts-low-contrast);
  }

  &[data-size='small'] {
    height: 2.4rem;
    padding: 0 0.4rem;
  }

  &:hover,
  &:focus-visible {
    background: var(--color-grayscale-trout) !important;
    border-color: var(--color-grayscale-trout) !important;
    cursor: pointer;

    & > span,
    & > svg {
      color: var(--color-texts-high-contrast);
    }
  }

  &[data-disabled='true'] {
    cursor: default;

    > div > span {
      color: var(--color-grayscale-shuttle);
    }

    &:hover,
    &:focus-visible {
      background-color: transparent !important;
    }
  }
`;

const InfoTextLine = styled.span`
  font-size: 1.4rem;
  font-weight: 400;
  color: var(--color-texts-high-contrast);
`;

const ProjectLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
`;
