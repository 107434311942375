import styled from 'styled-components';

export const ScreenContainer = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  height: 100%;
  max-height: calc(100% - 4rem);
  position: relative;

  > table {
    padding-inline-end: var(--spacing-inline-end-screen-container-child);
  }
`;

const TriggerButton = styled.button`
  position: relative;
  all: unset;
  width: 2.8rem;
  height: 2.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 2.8rem;
  font-size: 1.8rem;
  border-radius: 0.4rem;
  transition: 0.1s ease-in background-color;
  user-select: none;
  background-color: var(--color-grey-8);
  color: #fff;

  &:disabled {
    color: #9b9b9b;

    &:hover {
      cursor: auto;
    }
  }

  cursor: pointer;

  &[data-size='large'] {
    width: 3.2rem;
    height: 3.2rem;
  }

  &[data-size='free-size'] {
    width: unset;
    height: unset;
  }
`;

export const DropdownTrigger = styled(TriggerButton)`
  background: var(--color-grayscale-tuna);
  color: var(--color-grayscale-manatee);
  width: 2.8rem;
  height: 2.8rem;

  &[data-size='small'] {
    width: 2.4rem;
    height: 2.4rem;
  }

  &:hover,
  &:focus {
    color: var(--color-grayscale-white);
    background: var(--color-grayscale-arsenic);
  }

  &[data-dark='true'] {
    background: var(--color-grayscale-charleston);
  }
`;
