import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import { ProjectOverviewHeader } from '../../../features/projects/components/project-overview-header';
import { OverviewTab } from '../components/overview-tab';
import { useCurrentProject } from 'hooks/use-current-project';
import { Header } from '../project.page';
import { TaskPanel } from '../../../features/tasks/components/task.panel';
import { uiStore } from 'core/stores/ui-store';

export const ProjectRootScreen = observer(() => {
  const project = useCurrentProject()!;

  return (
    <>
      <Header>Overview</Header>

      <Content>
        <ProjectOverviewHeader project={project} />

        {/*<ProjectContextualDrawer project={project} onSelectTab={() => {}} /> */}

        <Container>
          <OverviewTab project={project} />

          {uiStore.isTaskPanelOpen && !!uiStore.taskPanelTargetId && (
            <TaskPanel targetId={uiStore.taskPanelTargetId} />
          )}
        </Container>
      </Content>
    </>
  );
});

const Container = styled.div`
  margin: 1.6rem;
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 100%;
`;

const Content = styled.div`
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
`;
