import React, { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import { SingleSelectDropdown } from '../../../components/select-dropdown';
import { Member } from '../../../app/entities/member';
import { PersonIcon } from '@radix-ui/react-icons';
import { Avatar } from '@producer-io/ui-kit';
import { CustomAvatar } from './styled-tasks-components';
import { Project } from '../../../app/entities/project';

type TaskAssigneeSelectorProps = {
  project?: Project;
  onChange: (value?: string) => void;
  selectedMemberId?: string | undefined | null;
  iconOnly?: boolean;
  isViewOnly?: boolean;
};

export const TaskAssigneeSelector: React.FC<TaskAssigneeSelectorProps> = observer(
  ({ project, onChange, selectedMemberId, iconOnly, isViewOnly }) => {
    const spaceMembers = Member.getAll();

    const projectMembers = useMemo(
      () => (project?.collaborators.map((collaborator) => collaborator.member) as Member[]) || [],
      [project?.collaborators],
    );

    const otherMembers = useMemo(
      () =>
        spaceMembers
          .filter((member) => !projectMembers.includes(member))
          .sort((a, b) => a.fullName.localeCompare(b.fullName)),
      [spaceMembers, projectMembers],
    );

    const memberOptions: React.ComponentProps<typeof SingleSelectDropdown>['options'] =
      projectMembers?.map((el) => ({
        label: el.fullName,
        value: el._id,
        icon: <Avatar size="small" initials={el.initials} src={el.avatar} />,
      })) || [];

    const spaceMemberOptions: React.ComponentProps<typeof SingleSelectDropdown>['options'] =
      otherMembers?.map((el) => ({
        label: el.fullName,
        value: el._id,
        icon: <Avatar size="small" initials={el.initials} src={el.avatar} />,
      })) || [];

    const options: React.ComponentProps<typeof SingleSelectDropdown>['options'] = [
      {
        label: 'No assignee',
        value: '',
        icon: (
          <CustomAvatar>
            <PersonIcon />
          </CustomAvatar>
        ),
      },
      ...memberOptions,
    ];

    const groups: React.ComponentProps<typeof SingleSelectDropdown>['groups'] = [
      {
        items: options,
      },

      {
        title: 'People not yet in project',
        items: spaceMemberOptions,
      },
    ];

    const handleStepChange = (value?: string) => {
      onChange(value || '');
    };

    const assignee = selectedMemberId && (Member.getOne(selectedMemberId) as Member);

    const TriggerContent = (label?: string) => {
      if (iconOnly) {
        return assignee ? (
          <Avatar size="small" initials={assignee.initials} src={assignee.avatar} />
        ) : (
          <CustomAvatar>
            <PersonIcon />
          </CustomAvatar>
        );
      }

      return assignee ? (
        <StepLabel>
          <Avatar size="small" initials={assignee.initials} src={assignee.avatar} />

          <InfoTextLine tabIndex={0}>{label}</InfoTextLine>
        </StepLabel>
      ) : (
        <>
          <PersonIcon />

          {!iconOnly && <span>No assignee</span>}
        </>
      );
    };

    return (
      <Container data-icon-only={iconOnly} data-disabled={isViewOnly}>
        <SingleSelectDropdown
          trigger={(label) => (
            <Trigger
              data-placeholder={!selectedMemberId}
              data-icon-only={iconOnly}
              data-disabled={isViewOnly}
              tabIndex={0}
            >
              {TriggerContent(label)}
            </Trigger>
          )}
          title="Assignee"
          placeholder={'Assign to...'}
          value={selectedMemberId || ''}
          groups={groups}
          onValueChange={handleStepChange}
          canOpen={!isViewOnly}
          showCheckbox={true}
          disabled={isViewOnly}
        />
      </Container>
    );
  },
);

const Container = styled.div`
  height: 3.2rem;
  min-width: 8rem;
  width: fit-content;
  overflow: hidden;
  border: 0.1rem solid transparent;
  border-radius: 0.4rem;

  &:hover {
    cursor: pointer;
  }

  &[data-icon-only='true'] {
    min-width: 0;
    max-width: 2.4rem;
    max-height: 2.4rem;
    border: none;
  }

  &[data-disabled='true'] {
    cursor: default;
  }
`;

const Trigger = styled.div`
  display: flex;
  min-width: 0;
  height: 100%;
  padding: 0.8rem;
  background: var(--color-surfaces-bg-elevation-2);
  align-items: center;
  gap: 0.8rem;
  font-size: 1.4rem;
  font-weight: 400;
  border-radius: 0.4rem;
  background-color: transparent;
  border: 0.1rem solid transparent;

  &[data-disabled='true'] {
    pointer-events: none;
    cursor: default;
  }

  &[data-icon-only='true'] {
    padding: 0;
    min-width: 2.4rem;
    min-height: 2.4rem;
    align-items: center;
    justify-content: center;
    border: none;
  }

  &:hover,
  &:focus-visible {
    cursor: pointer;
    border: 0.1rem solid transparent;
    background: var(--color-grayscale-trout) !important;
    border-color: var(--color-grayscale-trout) !important;
  }

  & > span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &[data-placeholder='true'] {
    flex-direction: row;
    background: transparent;
    color: var(--color-texts-low-contrast);
    border: 0.1rem dashed var(--color-texts-low-contrast);
  }
`;

const InfoTextLine = styled.span`
  font-size: 1.4rem;
  font-weight: 400;
  color: var(--color-texts-high-contrast);
`;

const StepLabel = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;
`;
