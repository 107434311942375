import React from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { CoverPlaceholder } from '../../strip-board/components/cover-placeholder';
import { CollapsibleItem } from '../../../components/collapsible-item/collapsible-item';
import { timeParser } from '../../../lib/utils/time-parser';
import { ShotListMenu } from './shot-list-menu';
import {
  CellContent,
  CellLabel,
  HorizontalCell,
  VerticalCell,
} from '../../shootingdays/components/styled-mobile-table';
import {
  CoverItem,
  OrderIndicator,
  Image,
  HiddenCoverOverlay,
} from '../../schedule/components/styled-strips';
import { Shot } from '../../../app/entities/shot';

import type { Frame } from '../../../lib/utils/transform-image';
import type { ShotListItemProps } from './shot-list-item';
import { ShotStrip } from '../../schedule/models/types';

type MobileShotListItemProps = Omit<
  ShotListItemProps,
  'isDragging' | 'isSelected' | 'style' | 'onClick'
>;

export const MobileShotListItem: React.FC<MobileShotListItemProps> = observer(
  ({ stripboard, strip }) => {
    const { t } = useTranslation('shot');
    const shot = Shot.getOne((strip as ShotStrip).data.shotId);

    const handleEdit = (data: Partial<Omit<Shot, '_id'>> & { file?: Frame }): void => {
      if (!shot) {
        return;
      }

      shot.update(data);
    };

    if (!shot) return null;

    return (
      <Container>
        <CollapsibleItem
          title={
            <Header>
              <CoverItem>
                {!!shot.order && <OrderIndicator>{shot.order}</OrderIndicator>}

                {shot.cover?.src ? (
                  <Image src={shot.cover?.src || ''} />
                ) : (
                  <CoverPlaceholder onUpload={(file) => handleEdit({ file })} />
                )}

                <HiddenCoverOverlay data-hide={!(strip as ShotStrip).data.isHidden} />
              </CoverItem>

              <HeaderDataWrapper data-hidden={!!(strip as ShotStrip).data.isHidden}>
                <ShotTitle>{shot.title}</ShotTitle>

                {!(strip as ShotStrip).data.isHidden && (
                  <HeaderData>
                    <CellLabel>Est. Time</CellLabel>

                    <CellContent>{timeParser(shot.estimatedTime || 0).toString()}</CellContent>
                  </HeaderData>
                )}
              </HeaderDataWrapper>
            </Header>
          }
          isTransparent
        >
          <ContentContainer data-hidden={!!(strip as ShotStrip).data.isHidden}>
            <VerticalCell>
              <CellLabel>Description</CellLabel>

              {shot.description && <CellContent>{shot.description}</CellContent>}
            </VerticalCell>

            <HorizontalCell>
              <CellLabel>Shot size</CellLabel>

              {shot.shotSize && <CellContent>{t(`${shot.shotSize}`)}</CellContent>}
            </HorizontalCell>

            <HorizontalCell>
              <CellLabel>Angle</CellLabel>

              {shot.angle && <CellContent>{t(`shot:${shot.angle}`)}</CellContent>}
            </HorizontalCell>

            <HorizontalCell>
              <CellLabel>Movement</CellLabel>

              {shot.movement && <CellContent>{t(`shot:${shot.movement}`)}</CellContent>}
            </HorizontalCell>

            <HorizontalCell>
              <CellLabel>Lens</CellLabel>

              <CellContent>{shot.lens}</CellContent>
            </HorizontalCell>

            <HorizontalCell>
              <CellLabel>FPS</CellLabel>

              <CellContent>{shot.fps || 25}</CellContent>
            </HorizontalCell>

            <HorizontalCell>
              <CellLabel>Actions</CellLabel>

              <ShotListMenu
                stripId={strip._id}
                shot={shot}
                stripboard={stripboard}
                isHidden={!!(strip as ShotStrip).data.isHidden}
              />
            </HorizontalCell>
          </ContentContainer>
        </CollapsibleItem>
      </Container>
    );
  },
);

const Container = styled.div`
  background-color: var(--color-grayscale-charleston);
  border-radius: 0.4rem;

  & > * {
    #collapsible-trigger {
      padding: 0.8rem;

      & > div:first-child {
        width: 100%;
      }

      & button {
        background-color: var(--color-grayscale-tuna);
      }
    }
  }
`;

const ContentContainer = styled.div`
  padding: 0 1.6rem 1.6rem;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;

  &[data-hidden='true'] {
    ${CellContent} {
      color: var(--color-grayscale-trout);
    }
  }
`;

const ShotTitle = styled.h3`
  color: var(--color-grayscale-white);
  font-size: 1.2rem;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.2rem;

  & ${CoverItem} {
    min-width: 12rem;
  }
`;

const HeaderDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;

  &[data-hidden='true'] {
    ${ShotTitle} ,${CellContent} {
      color: var(--color-grayscale-trout);
    }
  }
`;

const HeaderData = styled(HorizontalCell)`
  max-width: 12rem;
  width: 100%;
`;
