import React from 'react';
import { observer } from 'mobx-react-lite';
import { CalendarIcon } from '@radix-ui/react-icons';

import { formatDate } from '../../lib/utils/DateHelper';
import { dateShort } from '../../assets/contants/dates-fns';
import { KanbanCardAction } from '../../features/kanban/components/kanban-card-action';

interface IconSmallProps {
  onClick: (e: React.MouseEvent) => void;
  date?: string;
}

export const Date: React.FC<IconSmallProps> = observer(({ date, onClick }) => {
  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    onClick(e);
  };

  return (
    <KanbanCardAction
      onClick={handleClick}
      icon={<CalendarIcon />}
      text={date ? formatDate(date, dateShort) : ''}
    />
  );
});
