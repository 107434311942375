import React, { useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router';
import { DownloadIcon, Pencil1Icon, Share2Icon, TrashIcon } from '@radix-ui/react-icons';

import { ScreenHeader } from '../../projects/components/screen-header';
import { DeleteDialog } from '../../../components/dialogs/DeleteDialog';
import { ShareModal } from '../../share/components/share-modal';
import { download } from '../../../lib/utils/download';
import { DropdownMenu } from '../../../components/modals/dropdown-menu/DropdownMenu';
import { RenameStepModal } from '../../process/components/rename-step.modal';

import type { Step } from '../../../app/entities/step';
import { Asset } from '../../../app/entities/asset';

type AssetFilesHeaderProps = {
  step: Step;
  fileIds: string[];
};

export const AssetFilesHeader: React.FC<AssetFilesHeaderProps> = observer(({ step, fileIds }) => {
  const navigate = useNavigate();
  const [isDeleteStepModalOpen, setIsDeleteStepModalOpen] = useState(false);
  const [isDeleteAssetsModalOpen, setIsDeleteAssetsModalOpen] = useState(false);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);

  const [fileIdsToDownload] = useMemo(() => {
    const fileIdsToDownload =
      step.assets?.filter((file) => file.type !== 'link')?.map((file: any) => file._id) || [];

    return [fileIdsToDownload];
  }, [step.assets]);

  const handleDeleteStep = async () => {
    await step.delete();
    const href = `../..`;
    navigate(href);
  };

  const handleDeleteAssets = async () => {
    await Asset.deleteMany(fileIds);
  };

  const downloadAllAssets = async () => {
    if (!step.assets.length || !fileIdsToDownload.length) {
      return;
    }

    for (const id of fileIdsToDownload) {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      download(id);
    }
  };

  const items: React.ComponentProps<typeof DropdownMenu>['items'] = [
    {
      title: 'Share all',
      icon: <Share2Icon />,
      disabled: !step.assets.length || !fileIds.length,
      onSelect: () => setIsShareModalOpen(true),
    },
    {
      title: 'Download all',
      icon: <DownloadIcon />,
      disabled: !step.assets.length || !fileIdsToDownload.length,
      onSelect: downloadAllAssets,
    },
    {
      title: 'Rename step',
      icon: <Pencil1Icon />,
      onSelect: () => setIsRenameModalOpen(true),
    },
    {
      title: 'Delete all files',
      icon: <TrashIcon />,
      type: 'danger' as const,
      onSelect: () => setIsDeleteAssetsModalOpen(true),
    },
    {
      title: 'Delete step',
      icon: <TrashIcon />,
      type: 'danger' as const,
      onSelect: () => setIsDeleteStepModalOpen(true),
    },
  ];

  return (
    <>
      <ScreenHeader
        stepId={step._id}
        title={step.name}
        dueDate={step.dueDate}
        startDate={step.startDate}
        handleUpdateTimeFrame={(values) => step.update(values)}
        menu={<DropdownMenu items={items} size={'large'} />}
      />

      {isDeleteAssetsModalOpen && (
        <DeleteDialog
          title="Delete files"
          text="Are you sure you want to delete all the files in this step?"
          onCancel={() => setIsDeleteAssetsModalOpen(false)}
          onSubmit={handleDeleteAssets}
        />
      )}

      {isDeleteStepModalOpen && (
        <DeleteDialog
          title="Delete step"
          text={`Are you sure you want to delete this step?${
            step.tasks?.length ? ' All linked tasks will be gone as well.' : ''
          }`}
          onCancel={() => setIsDeleteStepModalOpen(false)}
          onSubmit={handleDeleteStep}
        />
      )}

      {isShareModalOpen && (
        <ShareModal
          elementIds={fileIds}
          spaceId={step.project!.spaceId}
          projectId={step.projectId}
          onClose={() => setIsShareModalOpen(false)}
        />
      )}

      {isRenameModalOpen && (
        <RenameStepModal step={step} onCancel={() => setIsRenameModalOpen(false)} />
      )}
    </>
  );
});
