import React from 'react';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import { ShotListStrip } from './sortable-shotlist-strip';

import type { ShotListProps } from './shot-list';
import { MobileDayStartStrip } from '../../schedule/components/day-start-strip.mobile';
import { DayBreakStrip } from '../../schedule/models/types';

export const MobileShotListTable: React.FC<ShotListProps> = observer(
  ({ stripboard, project, strips }) => {
    const hasShots = !!project.shots.length;

    const firstDayBreakStrip = strips?.find((strip) => strip.type === 'dayBreak');

    return (
      <>
        {hasShots && (
          <Container>
            {firstDayBreakStrip && (
              <MobileDayStartStrip
                stripboard={stripboard}
                strip={firstDayBreakStrip as DayBreakStrip}
              />
            )}
            {strips?.map((strip) => (
              <ShotListStrip
                key={strip._id}
                stripboard={stripboard}
                project={project}
                strip={strip}
                screenType="mobile"
              />
            ))}
          </Container>
        )}
      </>
    );
  },
);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
`;
