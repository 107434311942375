import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { DownloadIcon, Share2Icon, TrashIcon } from '@radix-ui/react-icons';

import { ScreenHeader } from '../../projects/components/screen-header';
import { ScreenContainer } from '../../projects/components/styled-project-components';
import { LocationBreadcrumb } from '../components/location-breadcrumb';
import { download } from '../../../lib/utils/download';
import { ShareModal } from '../../share/components/share-modal';
import { DropdownMenu } from '../../../components/modals/dropdown-menu/DropdownMenu';

import { ActionBar } from '../../assets/components/action-bar';
import { DeleteDialog } from '../../../components/dialogs/DeleteDialog';
import { FolderView } from '../../assets/components/folder-view';
import styled from 'styled-components';
import { Asset } from '../../../app/entities/asset';
import { Location } from '../../../app/entities/location';

import type { ScreenProps } from '../../projects/models/types';
import { uiStore } from '../../../core/stores/ui-store';
import { TaskPanel } from '../../tasks/components/task.panel';

export const LocationFilesScreen: React.FC<ScreenProps> = observer(
  ({ projectId, step, spaceId }) => {
    const { locationId } = useParams() as { locationId: string };

    const [isDeleteAssetsModalOpen, setIsDeleteAssetsModalOpen] = useState(false);

    const [isShareModalOpen, setIsShareModalOpen] = useState(false);

    const location = Location.getOne(locationId)!;
    const folder = Asset.getOne(location.assetsFolderId || '')!;

    const fileIds = folder.children.map((file: any) => file._id) || [];
    const fileIdsToDownload =
      folder.children?.filter((file) => file.type !== 'link')?.map((file: any) => file._id) || [];

    const downloadAllAssets = async () => {
      if (!location.assets.length || !fileIdsToDownload.length) {
        return;
      }

      for (const id of fileIdsToDownload) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        download(id);
      }
    };

    const options = [
      {
        title: 'Share all',
        icon: <Share2Icon />,
        disabled: !fileIds.length,
        onSelect: () => setIsShareModalOpen(true),
      },
      {
        title: 'Download all',
        icon: <DownloadIcon />,
        disabled: !fileIdsToDownload.length,
        onSelect: downloadAllAssets,
      },
      {
        title: 'Delete all files',
        icon: <TrashIcon />,
        type: 'danger' as const,
        disabled: !fileIds.length,
        onSelect: () => setIsDeleteAssetsModalOpen(true),
      },
    ];

    const handleDeleteAssets = async () => {
      await Asset.deleteMany(fileIds);
    };

    return (
      <>
        <ScreenHeader
          breadcrumb={<LocationBreadcrumb locationId={locationId!} />}
          menu={<DropdownMenu items={options} size="large" />}
          stepId={step._id}
          title={step.name}
          dueDate={step.dueDate}
          startDate={step.startDate}
          handleUpdateTimeFrame={(values) => step.update(values)}
          canValidateStep={false}
        />

        {isDeleteAssetsModalOpen && (
          <DeleteDialog
            title="Delete files"
            text="Are you sure you want to delete all the files in this step?"
            onCancel={() => setIsDeleteAssetsModalOpen(false)}
            onSubmit={handleDeleteAssets}
          />
        )}

        <Content>
          <FolderView stepId={step._id} folderId={location.assetsFolderId || ''} isRoot />
        </Content>

        {isShareModalOpen && (
          <ShareModal
            elementIds={fileIds}
            spaceId={spaceId!}
            projectId={projectId}
            onClose={() => setIsShareModalOpen(false)}
          />
        )}

        <ActionBar spaceId={spaceId!} projectId={step.projectId} />
      </>
    );
  },
);

const Content = styled(ScreenContainer)`
  max-height: calc(100% - 4rem);
`;
