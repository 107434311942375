import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { AddCollaboratorCard } from '../components/AddCollaboratorCard';
import { CollaboratorCard } from '../components/CollaboratorCard';
import { CollaboratorsGrid } from '../../../components/grid';
import { AddKeyContactModal } from '../components/add-key-contact.modal';
import { ScreenContainer } from '../../projects/components/styled-project-components';
import { KeyContactHeader } from '../components/key-contact-screen.header';
import { StepDrawer } from '../../projects/components/step.drawer';

import type { CollaboratorScreenProps } from '../models/types';
import { uiStore } from '../../../core/stores/ui-store';
import { TaskPanel } from '../../tasks/components/task.panel';

export const ProducerScreen: React.FC<CollaboratorScreenProps> = observer(({ project, step }) => {
  const contacts =
    project.contacts?.filter((contact) => contact.role?.toLowerCase() === 'producer') || [];

  const [isAddContactOpen, setIsAddContactOpen] = useState(false);

  useEffect(() => {
    return () => uiStore.closeTaskPanel();
  }, []);

  return (
    <React.Fragment>
      <KeyContactHeader step={step} />

      <StepDrawer step={step} />

      <ScreenContainer>
        <CollaboratorsGrid>
          {contacts.map((contact) => (
            <CollaboratorCard key={contact._id} project={project} contact={contact} />
          ))}

          <AddCollaboratorCard onClick={() => setIsAddContactOpen(true)} label="Add producer" />
        </CollaboratorsGrid>

        {uiStore.isTaskPanelOpen && !!uiStore.taskPanelTargetId && (
          <TaskPanel targetId={uiStore.taskPanelTargetId} />
        )}
      </ScreenContainer>

      <AddKeyContactModal
        projectId={project._id}
        spaceId={project.spaceId}
        onCancel={() => setIsAddContactOpen(false)}
        title="Select producer"
        position="Producer"
        isOpen={isAddContactOpen}
      />
    </React.Fragment>
  );
});
