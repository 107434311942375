import { makeObservable, observable } from 'mobx';

import { Model } from '../../core/engine/model';
import { api } from '../../api';
import { ManyToOne } from '../../core/engine/decorators';
import { entityPool } from '../../core/engine/engine';
import { Project, ProjectPriority } from './project';
import { catchError } from '../../core/catch-error';
import { Member } from './member';
import { Step } from './step';
import { Space } from './space';

export enum TaskStatus {
  Todo = 'todo',
  InProgress = 'in-progress',
  OnHold = 'on-hold',
  Done = 'done',
  Canceled = 'canceled',
}

export type TaskLevel = 'space' | 'project' | 'step';

export type SaveTaskDto = {
  title: string;
  status: TaskStatus;
  priority?: ProjectPriority;
  dueDate?: string;
  description?: string;
  projectId?: string;
  stepId?: string;
  assigneeId?: string;
};

export type GetTasksDto = {
  role: ('created-by-me' | 'assigned-to-me')[];
  status: TaskStatus[];
  priority: ProjectPriority[];
  projectId: string;
  stepId: string;
};

export class Task extends Model {
  spaceId: string;

  creatorId: string;

  title: string;

  status: TaskStatus;

  priority: ProjectPriority;

  dueDate?: string;

  projectId?: string;

  stepId?: string | null;

  assigneeId?: string | null;

  description?: string;

  @ManyToOne('tasks')
  space: Space;

  @ManyToOne('tasks')
  project?: Project;

  @ManyToOne('tasks')
  step?: Step;

  @ManyToOne('tasks')
  assignee?: Member;

  constructor() {
    super('tasks');

    this.status = TaskStatus.Todo;
    this.priority = ProjectPriority.NO_PRIORITY;

    makeObservable<Task>(this, {
      _id: observable,
      spaceId: observable,
      creatorId: observable,
      title: observable,
      status: observable,
      priority: observable,
      dueDate: observable,
      projectId: observable,
      stepId: observable,
      assigneeId: observable,
      description: observable,
    });
  }

  static StatusMapper: Record<TaskStatus, { title: string }> = {
    todo: {
      title: 'Todo',
    },
    'on-hold': {
      title: 'On Hold',
    },
    'in-progress': {
      title: 'In Progress',
    },
    canceled: {
      title: 'Canceled',
    },
    done: {
      title: 'Done',
    },
  };

  static async create(input: SaveTaskDto): Promise<Task | undefined> {
    try {
      const { data: task } = await api.post(`/tasks`, input);

      entityPool.insert(Object.assign(new Task(), { ...task }));

      return task;
    } catch (e) {
      catchError(e);
    }
  }

  static fetchAll = async (input?: Partial<GetTasksDto>) => {
    const { role, status, priority } = input || {};

    const params = {
      ...input,
      role: role?.join(','),
      status: status?.join(','),
      priority: priority?.join(','),
    };

    const { data } = await api.get(`/tasks`, { params });

    data.forEach((task: Task) => {
      entityPool.insert(Object.assign(new Task(), task));
    });

    return data;
  };

  static getAll() {
    return Model.getAll().filter((model) => model.$name === 'tasks') as Task[];
  }

  toPOJO(): Record<string, any> {
    return {
      _id: this._id,
      spaceId: this.spaceId,
      creatorId: this.creatorId,
      projectId: this.projectId,
      status: this.status,
      dueDate: this.dueDate,
      title: this.title,
      priority: this.priority,
      stepId: this.stepId,
      assigneeId: this.assigneeId,
      description: this.description,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
    };
  }
}
