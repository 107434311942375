import React, { useState } from 'react';
import styled from 'styled-components';
import { CalendarIcon } from '@radix-ui/react-icons';
import { format } from 'date-fns';

import { DateSelectionModal } from '../../features/process/components/date-selection.modal';
import { breakPoint } from '../../app/theme';
import { dateShort } from '../../assets/contants/dates-fns';

interface DateSelectorProps {
  date?: Date | string;
  min?: Date | string;
  max?: Date | string;
  placeholder?: string;
  onUpdate: (value: string) => void;
  field: 'startDate' | 'dueDate';
  disabled?: boolean;
}

export const DateSelector: React.FC<DateSelectorProps> = ({
  date,
  field,
  onUpdate,
  max,
  min,
  placeholder = 'Set date',
  disabled,
}) => {
  const [isEditDateOpen, setIsEditDateOpen] = useState(false);

  const handleUpdateDate = async (value: any) => {
    onUpdate(value);
  };

  const dateValidator = (value: string) => {
    const date = new Date(value).setUTCHours(0, 0, 0, 0);

    if (min) {
      const minDate = new Date(min);
      minDate.setUTCHours(0, 0, 0, 0);

      return minDate.getTime() <= date;
    }

    if (max) {
      const maxDate = new Date(max);
      maxDate.setUTCHours(0, 0, 0, 0);

      return maxDate.getTime() >= date;
    }

    return true;
  };

  return (
    <>
      <DateAction
        data-disabled={disabled}
        data-dateSet={!!date}
        data-isStartDate={field === 'startDate'}
        onClick={() => setIsEditDateOpen(true)}
      >
        <CalendarIcon />
        <span>{date ? format(new Date(date), dateShort) : placeholder}</span>
      </DateAction>

      {isEditDateOpen && (
        <DateSelectionModal
          onCancel={() => setIsEditDateOpen(false)}
          fieldName={field}
          value={date ? format(new Date(date), dateShort) : new Date()}
          onSubmit={handleUpdateDate}
          dateValidator={dateValidator}
        />
      )}
    </>
  );
};

const DateAction = styled.div`
  display: flex;
  align-items: center;
  color: var(--color-grayscale-manatee);
  font-size: 1.2rem;
  font-weight: 400;
  cursor: pointer;
  border-radius: 0.4rem;
  padding-left: 0;
  gap: 0.8rem;

  &:hover {
    background-color: var(--color-grayscale-charleston);
  }

  &[data-dateSet='true'] {
    &:hover {
      background-color: transparent;
    }
  }

  &[data-dateSet='true'] {
    svg,
    span {
      color: var(--color-grayscale-white);
    }
  }

  @media screen and (min-width: ${breakPoint.medium}px) {
    padding: 0.4rem;
  }

  &[data-isStartDate='true'] {
    padding-left: 0;
  }
`;
