import React from 'react';
import styled from 'styled-components';

import { UploadButton } from './upload-button';
import { Upload } from '../../../components/icons/CloudForSelectingFile';

type DropFileForAddProps = {
  handleSubmitFile: (files: File[]) => Promise<void>;
};

export const FileDropzone: React.FC<DropFileForAddProps> = ({ handleSubmitFile }) => (
  <div style={{ height: '100%', flexGrow: 1, padding: '1.6rem' }}>
    <Container>
      <Upload />

      <Title>Select file(s) or drag and drop here</Title>

      <UploadButton text="Select file(s)" onUploadFileValue={handleSubmitFile} multiple />
    </Container>
  </div>
);

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: rgba(54, 123, 255, 0.08);
  border: 0.2rem dashed #367bff;
  border-radius: 0.5rem;
`;

const Title = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.9rem;
  color: #ffffff;
  margin-top: 0.5rem;
  margin-bottom: 2.4rem;
`;
