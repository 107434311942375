import React, { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { ErrorScreen } from '../../../components/error-screen/ErrorScreen';
import { ShotList } from '../../shot-list/components/shot-list';
import { createStripBoard, fetchStripBoard } from '../../strip-board/strip-board.slice';
import { LoaderContainer } from '../../../routes/project/components/step-container';
import { PageLoader } from '../../../components/page-loader/PageLoader';
import { Project } from '../../../app/entities/project';
import { ShotListHeader } from '../components/shotlist-screen.header';
import { StepDrawer } from '../../projects/components/step.drawer';

import type { Step } from '../../../app/entities/step';
import { SelectionProvider } from '../../../core/contexts/selection.context';
import { useCurrentMember } from '../../../hooks/use-current-member';

type ShotListScreenProps = {
  project: Project;
  step: Step;
};

export const ShotListScreen: React.FC<ShotListScreenProps> = observer(({ project, step }) => {
  const [isLoading, setIsLoading] = useState(true);

  const currentMember = useCurrentMember();
  const userStepPreferences = currentMember?.preferences.stepsUI[step._id];

  const stripboard = project.stripboard;

  useEffect(() => {
    fetchStripBoard(project._id)
      .then(() => setIsLoading(false))
      .catch(() => setIsLoading(false));
  }, [project._id]);

  const handleCreateStripBoard = () => {
    createStripBoard({ projectId: project._id });
  };

  const shotListStrips = useMemo(() => {
    if (!stripboard?.strips) {
      return [];
    }

    return stripboard.strips.filter((strip) => {
      if (strip.type === 'banner') return false;

      if (userStepPreferences?.showHiddenShots !== false) return true;

      if (strip.type === 'shot') {
        return !strip.data.isHidden;
      }

      return true;
    });
  }, [stripboard.strips, userStepPreferences?.showHiddenShots]);

  const shotCount = useMemo(() => {
    if (!shotListStrips.length) return 0;
    return shotListStrips?.filter((strip) => strip.type === 'shot').length;
  }, [shotListStrips]);

  const dayBreakStripIds = useMemo(() => {
    return shotListStrips?.filter((strip) => strip.type === 'dayBreak')?.map((strip) => strip._id);
  }, [shotListStrips]);

  const stripIds = shotListStrips?.map((strip) => strip._id);

  return (
    <React.Fragment>
      <ShotListHeader step={step} project={project} shotCount={shotCount} />

      <StepDrawer step={step} />

      {isLoading ? (
        <LoaderContainer>
          <PageLoader />
        </LoaderContainer>
      ) : stripboard ? (
        <SelectionProvider items={stripIds} excludedItems={dayBreakStripIds}>
          <ShotList stripboard={stripboard} strips={shotListStrips!} project={project} />
        </SelectionProvider>
      ) : (
        <ErrorScreen
          title="You do not have a strip board for this project yet"
          message="Start your shot list"
          actionText="Create strip board"
          onActionClick={handleCreateStripBoard}
        />
      )}
    </React.Fragment>
  );
});
