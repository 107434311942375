import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';

import { CollaboratorsGrid } from '../../../components/grid';
import { CollaboratorCard } from '../components/CollaboratorCard';
import { AddCollaboratorCard } from '../components/AddCollaboratorCard';
import { AddKeyContactModal } from '../components/add-key-contact.modal';
import { ScreenContainer } from '../../projects/components/styled-project-components';
import { KeyContactHeader } from '../components/key-contact-screen.header';
import { StepDrawer } from '../../projects/components/step.drawer';
import { uiStore } from '../../../core/stores/ui-store';
import { TaskPanel } from '../../tasks/components/task.panel';

import type { CollaboratorScreenProps } from '../models/types';

export const LineProducerScreen: React.FC<CollaboratorScreenProps> = observer(
  ({ project, step }) => {
    const contacts =
      project.contacts?.filter((contact) => contact.role?.toLowerCase() === 'line producer') || [];

    const [isAddContactOpen, setIsAddContactOpen] = useState(false);

    useEffect(() => {
      return () => uiStore.closeTaskPanel();
    }, []);

    return (
      <React.Fragment>
        <KeyContactHeader step={step} />

        <StepDrawer step={step} />

        <ScreenContainer>
          <CollaboratorsGrid>
            {contacts.map((contact) => (
              <CollaboratorCard key={contact._id} project={project} contact={contact} />
            ))}

            <AddCollaboratorCard
              onClick={() => setIsAddContactOpen(true)}
              label="Add line producer"
            />
          </CollaboratorsGrid>

          {uiStore.isTaskPanelOpen && !!uiStore.taskPanelTargetId && (
            <TaskPanel targetId={uiStore.taskPanelTargetId} />
          )}
        </ScreenContainer>

        <AddKeyContactModal
          projectId={project._id}
          spaceId={project.spaceId}
          onCancel={() => setIsAddContactOpen(false)}
          title="Select line producer"
          position="Line producer"
          isOpen={isAddContactOpen}
        />
      </React.Fragment>
    );
  },
);
