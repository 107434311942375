import React from 'react';
import styled from 'styled-components';

import { Diapo } from '../../../components/icons/Diapo';

type ProjectListPlaceholderProps = {
  onCreateProject: () => void;
};

export const ProjectListPlaceholder: React.FC<ProjectListPlaceholderProps> = ({
  onCreateProject,
}) => {
  return (
    <Container>
      <Title>Projects</Title>
      <p>You haven’t created a project yet.</p>

      <Actions>
        <Action onClick={onCreateProject}>
          <ActionIcon>
            <Diapo />
          </ActionIcon>

          <ActionContent>
            <h3>Create a project</h3>
            <p>Start with a standard template.</p>
          </ActionContent>
        </Action>
      </Actions>
    </Container>
  );
};

const Container = styled.div`
  margin: 12.8rem auto 0;
  max-width: 76.8rem;
  width: 100%;
`;

const Title = styled.h2`
  margin-bottom: 1.8rem;
`;

const Actions = styled.div`
  margin-top: 1.2rem;
  padding: 3.2rem 0;
  border-bottom: solid 1px #42454b;
  border-top: solid 1px #42454b;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2.4rem;
`;

const Action = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: var(--border-radius-medium);
  padding: 0.8rem;
  transition: background-color 100ms ease-in-out;

  &:hover {
    cursor: pointer;
    background-color: #42454b80;
  }
`;

const ActionIcon = styled.div`
  height: 6.4rem;
  width: 6.4rem;
  background: var(--color-primary);
  border-radius: var(--border-radius-medium);
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    height: 2.4rem;
  }
`;

const ActionContent = styled.div`
  margin-left: 1.2rem;
`;
